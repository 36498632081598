<template>
  <div>
    <v-card
      class="mr-2"
      :max-height="maxHeight"
      :min-height="minHeight"
      style="overflow-y: auto;"
    >
      <v-card-text style="">
        <div style="font-size: 15px; font-weight: bold">Pagamento</div>
        <v-divider></v-divider>

        <div style="left: 50%; top: 50%" class="pa-2">
          <CashSectionBar
            class="mt-10"
            :bill="completeBill"
            :selectedTable="selectedTable"
            :partialPayment="partialPayment"
            :go-back="goBack"
            :return-bill="returnBill"
            :customer-id="customerId"
          ></CashSectionBar>
        </div>
      </v-card-text>
    </v-card>

  </div>
</template>

<script>
import Vue from "vue";
import CashSectionBar from "@/components/bar/daily-reservation/CashSectionBar";
export default {
  components: {
    CashSectionBar,
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
    maxWidht: {
      type: String,
      default: "20vw",
    },
    maxHeight: {
      type: String,
      default: "73dvh",
    },
    minWidht: {
      type: String,
      default: "20vw",
    },
    minHeight: {
      type: String,
      default: "73dvh",
    },
    selectedTable: {
      type: Object,
      default: () => {},
    },
    partialPayment: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: Number,
      default: undefined,
    },
    goBack: {
      default: undefined
    },
    returnBill:{
      default: undefined
    }
  },
  data() {
    return {
      localSelectedBill: { id : undefined},
      localSelectedBillItems: [],
      completeBill: undefined,
    };
  },
  mounted() {
    let self = this;
    this.bus.$on("reload-items", self.handleChangeBillItems);
    this.bus.$on("reload-bill", self.handleChangeBill);
  },
  methods: {
    handleChangeBill(bill) {
      this.localSelectedBill = bill;
      this.localSelectedBillItems = bill.billItems
      this.calcNewBill()
    },
    handleChangeBillItems(billItems) {
      this.localSelectedBillItems = billItems;
      this.calcNewBill()
    },
    calcNewBill() {
      let items = undefined
      let services = undefined
      if(!!this.localSelectedBillItems){
        items = this.localSelectedBillItems.filter(elem=>!!elem.code || (!!elem.item && !!elem.item.code))
        services = this.localSelectedBillItems.filter(elem=>!!elem.id && (!!elem.service && !!elem.service.id))
      }
      this.completeBill =  {
        ...this.localSelectedBill,
        billItems: items,
        billServices: services
      };
    },
  },
};
</script>

<style>
</style>