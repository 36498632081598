<template>
  <StandardDialog
    dialog-width="90vw"
    dialog-height="calc(100vh-1)"
    v-model="localOpenMenu"
    persistent
    :title="reservationTitle"
  >
    <v-row no-gutters>
      <v-col
        cols="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
      >
        <v-card
          minHeight="60vh"
          maxHeight="60vh"
          height="100%"
          class="mt-2 mr-2"
        >
          <v-card-text style="max-height: 100%; height: 100%;">
            <div style="font-size: 15px; font-weight: bold">Lista Menu</div>
            <v-divider></v-divider>
            <Hierarchy
              :hierarchy="menus"
              context="menu"
              item-title="name"
              item-children="menuTypes"
              style="max-height: 100%; height: 90%;"
              :disabled="loadingBill"
              @final="addEntity"
              class="mt-2"
            >
              <template v-slot:final-action="{ item }">
                <span class="text-overline" v-if="!!item.price">€ {{item.price}}</span>
                <span class="text-overline" v-else-if="!!item.priceSelling">€ {{item.priceSelling}}</span>
              </template>
            </Hierarchy>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col 
        cols="12"
        sm="12"
        md="6"
        lg="3"
        xl="3"
      >
        <div 
          style="min-height: 60vh; max-height: 60vh; height: 100%"          
          class="mt-2 mr-2"
        >
          <ListItemComponent
            title="Prodotti Ordinati"
            :selectablePayingVersion="true"
            :items="billEntities"
            :item-description="(item) => !!item.serviceId ? item.service.name : item.item.description"
            :item-price="(item) => !!item.serviceId ? item.service.price : item.item.priceSelling"
            :item-quantity="(item) => item.quantity"
            height="29vh"
            min-height="29vh"
            max-height="29vh"
            :show-actions="true"
            :loading="loadingListItemComponent"
            @add-item="(item) => !!item.item ? addEntity(item.item) : addEntity(item.service)"
            @remove-item="removeEntity"
            @delete-item="deleteEntity"
            @add-item-toPay="addBillEntityToPay"
            @select-tax="selectTax"
            @select-not-tax="selectNotTax"
            @add-discount="addDiscount"
            @item-ghost="handleItemGhost"
          ></ListItemComponent>
          <ListItemComponent
            title="Prodotti Da Pagare"
            :selectableDiscountOnTotal="true"
            :items="billEntitiesToPay"
            :item-description="(item) => !!item.serviceId ? item.service.name : item.item.description"
            :item-price="(item) => !!item.serviceId ? calculateNewPriceService(item) : calculateNewPriceItem(item)"
            :item-quantity="(item) => item.quantity"
            height="30vh"
            min-height="30vh"
            max-height="30vh"
            :show-actions="false"
            @add-item="addEntity"
            @remove-item="removeEntity"
            @delete-item="deleteEntity"
            @remove-item-toPay="removeBillEntityToPay"
            @select-tax="selectTax"  
            @select-not-tax="selectNotTax"
            @add-discount="addDiscount"
            @show-general-discount-form="openDiscountGeneralDialog"
            :style="{
              'margin-top': $vuetify.breakpoint.xl ? '13px' : '7px'
            }"
          ></ListItemComponent>
        </div>  
      </v-col>  
      <v-col 
        cols="12"
        sm="12"
        md="12"
        lg="3"
        xl="3"
      >
        <BarPayment 
          class="mt-2" 
          :bus="bus"
          :selectedTable="selectedTable"
          :partialPayment="partialPayment"
          minHeight="60vh"
          maxHeight="60vh"
          :go-back="goBack"
          :return-bill="returnBill"
          :customer-id="customerId"
        ></BarPayment>
      </v-col>
    </v-row>
    
    <DiscountForm 
      v-model="discountItem"
      :entity-detail="entityDetail"
      @updateValid="handleDiscountFormValid"
      :open-dialog.sync="discountDialog"
      price-mode="edit"
      :type="type"
      title="Modifica Prezzo"
      @set-discount="setDiscount"
      @remove-discount="removeDiscount"
    >
    </DiscountForm>

    <StandardDialog 
      v-model="discountGeneralDialog" 
      dialog-height="calc(60vh - 1)" 
      dialog-width="40vw"
      title="Assegna Sconto" 
      persistent
    >

      <DiscountFormBar 
        v-model="discountBar"
        @updateValid="handleDiscountBarFormValid"
        :max-price="maxPrice"
      >
      </DiscountFormBar>

      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="discountGeneralDialog = false">Chiudi</v-btn>
        <v-btn v-if="!!discountBar && !!discountBar.editPriceType && !!discountBar.editPriceValue"
         text
         color="default"
         @click="removeDiscountBar">
          Rimuovi
        </v-btn>
        <v-btn text color="success"
          :disabled="!discountBarFormValid"
          @click="setDiscountBar">
          Assegna
        </v-btn>
      </template>

    </StandardDialog>

    <SimplePopUp
      v-model="discountGeneralAlert"
      :width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '80vw' :
          $vuetify.breakpoint.xl ? '26vw' : 
          $vuetify.breakpoint.lg ? '30vw' : '35vw'"
      :alert-text="discountGeneralAlertText"
      @close-popup="closePopUp"
    ></SimplePopUp>

    <SimplePopUp
      v-model="partialPaymentAlert"
      :width="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? '80vw' :
          $vuetify.breakpoint.xl ? '26vw' : 
          $vuetify.breakpoint.lg ? '30vw' : '35vw'"
      :alert-text="partialPaymentAlertText"
    ></SimplePopUp>

    <template v-slot:footer>
      <v-spacer></v-spacer>
      <v-btn 
        text color="black" v-if="!hideCloseTable" 
        :loading="closeLoading"
        :disabled="(!!billEntitiesToPay && billEntitiesToPay.length > 0) 
          || loadingBill == true || loadingListItemComponent == true"
        @click="closeTable"
      >
        Chiudi Tavolo
      </v-btn>
      <v-btn 
        text color="black" v-if="showCloseBill"
        :loading="closeLoading"
        :disabled="(!!billEntitiesToPay && billEntitiesToPay.length > 0) 
          || loadingBill == true || loadingListItemComponent == true"
        @click="closeOccasionalBill"
      >
        Chiudi Conto
      </v-btn>
      <v-btn 
        text color="error"
        :disabled="(!!billEntitiesToPay && billEntitiesToPay.length > 0) 
          || loadingBill == true || loadingListItemComponent == true"
        @click="close"
      >
        Chiudi
      </v-btn>
    </template>

    
    <v-snackbar
      v-model="notEnoughQuantitySnackbar"
      :timeout="6000"
      absolute
      vertical
      bottom
      elevation="24"
    >
      Quantità non disponibile per l'articolo! Fare un movimento di magazzino.
      <template v-slot:action="{ attrs }">
        <v-btn
          color="rgb(80, 191, 225, 0.9)"
          v-bind="attrs"
          outlined
          @click="$router.push({ name: 'MovementUploadList' })"
        >
          Vai al Magazzino
        </v-btn>
      </template>
    </v-snackbar>
  </StandardDialog>
</template>

<script>
import ListItemComponent from "@/components/common/ListItemComponent.vue";
import Hierarchy from "@/components/common/Hierarchy.vue";
import StandardDialog from "@/components/common/StandardDialog";
import menuService from "@/services/bar/menu.service.js";
import tableService from "@/services/bar/table.service.js";
import BarPayment from "@/components/bar/daily-reservation/BarPayment.vue";
import DiscountFormBar from "@/components/bar/daily-reservation/DiscountFormBar.vue";
import ScannerAddMenu from "@/components/bar/daily-reservation/ScannerAddMenu.vue";
import billService from "@/services/bills/bills.service";
import billBarCalculator from "@/services/bills/billBar.calculator.js"
import DiscountForm from "@/components/areas/registries/services/form/DiscountForm.vue"
import SimplePopUp from '@/components/common/SimplePopUp.vue'
import Vue from "vue";
import cashDeskService from '@/services/cashDesks/cashDesks.service.js'

export default {
  name: "MenuDialog",
  components: {
    ListItemComponent,
    StandardDialog,
    BarPayment,
    ScannerAddMenu,
    Hierarchy,
    DiscountForm,
    DiscountFormBar,
    SimplePopUp
  },
  props: {
    billId: {
      type: Number,
      default: undefined,
    },
    customerId: {
      type: Number,
      default: undefined,
    },
    openMenu: {
      type: Boolean,
      default: false,
    },
    selectedTable: {
      type: Object,
      default: () => {},
    },
    goBack: {
      default: undefined
    },
    returnBill:{
      default: undefined
    },
    hideCloseTable: {
      type: Boolean,
      default: false
    },
    showCloseBill: {
      type: Boolean,
      default: false
    },
  },
  mounted() {
    this.fetchMenus();
  },
  data() {
    return {
      localProvidedItems: this.providedItems,
      localOpenMenu: this.openMenu,
      menus: undefined,
      reservationTitle: '',
      tables: [],
      billEntities: [],
      billEntitiesToPay: [],
      bus: new Vue(),
      quantityPaid: 0, 
      quantityOrdered: 0,
      discountDialog: false,
      discountItem: undefined,
      discountBar: undefined,
      discountGeneralDialog: false,
      discountBarFormValid: false,
      discountFormValid: false,
      itemDetail: undefined,
      serviceDetail: undefined,
      entityDetail: undefined,
      type: undefined,
      loadingListItemComponent: false,
      closeLoading: false,
      taxForBar: undefined,
      notEnoughQuantitySnackbar: false,
      discountGeneralAlert: false,
      discountGeneralAlertText: 'Lo sconto generale a valore verrà ripartito partire dal prodotto/servizio con prezzo più alto.',
      closeDiscountGeneralAlertWithoutControl: false,
      partialPaymentAlert: false,
      partialPaymentAlertText: 'Attenzione! Il seguente è già stato gestito parzialmente in concordato',
      loadingBill: false
    };
  },
  methods: {
    reloadItems(items) {
      this.billEntities = items;
    },
    fetchMenus() {
      menuService.listForHierarchy().then((menus) => {
        this.menus = menus;
      });
    },
    close() {
      if(this.selectedTable == undefined && this.billEntities.length == 0)
        billService.deleteBill({id: this.billId})
      this.removeDiscountBar()
      this.$emit("close");
    },
    async closeTable() {
      if (this.billEntities.length == 0) {
        this.closeLoading = true
        await tableService.removeBillFromTable(this.selectedTable, 'delete').then(() => {
          this.removeDiscountBar()
          this.closeLoading = false
          this.$emit("close");
          this.$emit("reload");
        })
      }

      else if(this.billEntities.length > 0 
        && this.billEntities.filter(entity => Number(entity.paidQuantity) < Number(entity.quantity)).length > 0) {
        this.$delegates.snackbar('Ci sono ancora dei prodotti associati al tavolo')
        this.closeLoading = false
        return
      } 
      
      else {
        this.closeLoading = true
        const cashDesk = await cashDeskService.getOpened()
        
        if (!cashDesk) {
          this.$delegates.snackbar('Cassa non aperta')
          this.closeLoading = false
          return
        }
        
        await tableService.removeBillFromTable(this.selectedTable, 'close', cashDesk).then(() => {
          this.removeDiscountBar()
          this.closeLoading = false
          this.$emit("close");
          this.$emit("reload");
        });
      } 
    },
    async closeOccasionalBill() {
      if(this.billEntities.length > 0 
        && this.billEntities.filter(entity => Number(entity.paidQuantity) < Number(entity.quantity)).length > 0) {
        this.$delegates.snackbar('Ci sono ancora dei prodotti associati al conto')
        this.closeLoading = false
        return
      } 
      
      else {
        this.closeLoading = true
        const cashDesk = await cashDeskService.getOpened()

        if (!cashDesk) {
          this.$delegates.snackbar('Cassa non aperta')
          this.closeLoading = false
          return
        }

        await billService.updateBill({
          id: this.billId,
          status: 'closed',
          closedAt: new Date(),
          cashDeskId: cashDesk.id,
          cashDeskLogId: cashDesk.cashDeskLog.id
        }).then(() => {
          this.removeDiscountBar()
          this.closeLoading = false
          this.$emit("close");
          this.$emit("reload");
        });
      } 
    },
    async addEntity(item) {
      let tax = 10
      if(this.taxForBar !== null && this.taxForBar !== undefined)
        tax = this.taxForBar
      if(!!item.tax)
        tax = item.tax

      try {
        this.loadingListItemComponent = true
        let result
        if(!!item.code || (!!item.item && !!item.item.code)) {
          result = await billService.addItemToBill({id: this.billId}, !!item.code ? {...item, tax: tax} : {...item.item, tax: tax})
          if(result.length === 0)
            this.notEnoughQuantitySnackbar = true
        }
        if(!!item.id && !!item.serviceTypeId){ 
          result = await billService.addServiceToBill({id: this.billId}, {...item, tax: tax})
        }
        if(!!result)
          await this.fetchBillEntities()
        this.loadingListItemComponent = false  
      } catch (error) {
        console.log(error) 
        this.loadingListItemComponent = false  
      }
    },
    async selectTax(item){
      try {
        let result
        if(!!item.code || (!!item.item && !!item.item.code))
          result = await billService.addItemToBill({id: this.billId}, !!item.code ? {...item, tax: 22} : {...item.item, tax: 22});
        if(!!item.id && !!item.serviceType){ 
          result = await billService.addServiceToBill({id: this.billId}, {...item, tax: 22})
        }
        if(!!result){
          await this.fetchBillEntities()
          this.removeEntity(item)
        }
      } catch (error) {
        console.log(error) 
      }
    },
    async selectNotTax(item){
      try {
        let result
        if(!!item.code || (!!item.item && !!item.item.code))
          result = await billService.addItemToBill({id: this.billId}, !!item.code ? {...item, tax: 10} : {...item.item, tax: 10});
        if(!!item.id && !!item.serviceType){ 
          result = await billService.addServiceToBill({id: this.billId}, {...item, tax: 10})
        }
        if(!!result){
          await this.fetchBillEntities()
          this.removeEntity(item)
        }
      } catch (error) {
        console.log(error) 
      }
    },
    async removeEntity(item) {
      try {
        this.loadingListItemComponent = true
        let result

        const elFound = this.billEntitiesToPay.find(el => el.id == item.id)
        if(!!elFound) {
          elFound.quantity--
          elFound.ghostQuantity = elFound.quantity
          if(!elFound.quantity)
            this.billEntitiesToPay = this.billEntitiesToPay.filter( el =>  el.id != elFound.id )
        }

        if(!!item.code || (!!item.item && !!item.item.code))
          result = await billService.removeItemFromBill({id: this.billId},!!item.code ? item : {...item.item, tax: item.tax})

        if(!!item.id && (!!item.service && !!item.service.id))
          result = await billService.removeServiceFromBill({id: this.billId}, {...item.service, tax: item.tax})

        if(!!result)
          await this.fetchBillEntities()
          
        this.loadingListItemComponent = false
      } catch (error) {
        this.loadingListItemComponent = false
        console.log(error) 
      }
    },
    async deleteEntity(item) {
       try {
        this.loadingListItemComponent = true
        if(!!item.paidQuantity){
          let count = Number(item.quantity) - item.paidQuantity
          for(let i = 0; i < count; i++)
            await this.removeEntity(item)
        }
        else
         for(let i = 0; i < Number(item.quantity); i++)
            await this.removeEntity(item)

        this.loadingListItemComponent = false
      } catch (error) {
        this.loadingListItemComponent = false
        console.log(error) 
      }
    },
    async fetchBillEntities(){
      if(!this.billId)
        throw new Error('billId Not Present')
      try {
        this.quantityOrdered = 0
        this.quantityPaid = 0
        let entitiesList = await billService.entitiesList({id: this.billId})
        this.billEntities = entitiesList.map(el => {
          const elFound = this.billEntitiesToPay.find(elem => elem.id == el.id)
          if(!!elFound) 
            el.quantityBeingPaid = elFound.quantity
          else 
            el.quantityBeingPaid = 0
          this.quantityOrdered += Number(el.quantity)
          this.quantityPaid += Number(el.paidQuantity)
          return el
        })
        for (const billEntityToPay of this.billEntitiesToPay)
          this.quantityPaid += billEntityToPay.quantity
        this.bus.$emit("reload-items", this.billEntitiesToPay);
      } catch (error) {
        console.log(error)
      }  
    },
    addBillEntityToPay(entity){
      let newEntity = {...entity}
      const elFound = this.billEntitiesToPay.find(el => el.id == entity.id)
      if(!!elFound){
        elFound.quantity++
        if(entity.ghost)
          elFound.ghostQuantity = elFound.quantity
      }
      else {
        newEntity.quantity = 1
        if(entity.ghost)
          newEntity.ghostQuantity = 1
        this.billEntitiesToPay.push(newEntity)
      }  
      entity.quantityBeingPaid++
      this.quantityPaid++
      this.bus.$emit("reload-items", this.billEntitiesToPay);
    },
    removeBillEntityToPay(entity){
      if (!!this.discountBar && Object.keys(this.discountBar).length > 0
        && this.discountBar.editPriceType != undefined && this.discountBar.editPriceValue != undefined
      ) {
        this.discountGeneralAlertText = 'Attenzione!  Non è possibile rimuovere un prodotto/servizio con sconto generale. Rimuovere lo sconto generale per poter rimuovere il prodotto/servizio.'
        this.discountGeneralAlert = true
        this.closeDiscountGeneralAlertWithoutControl = true
        return
      }
        
      const elFound = this.billEntitiesToPay.find(el => el.id == entity.id)
      const elNotToPay = this.billEntities.find(el => el.id == entity.id)
      if(elFound.quantity > 1){
        elFound.quantity--
        if(entity.ghost)
          elFound.ghostQuantity = elFound.quantity
      }
      else{
        this.billEntitiesToPay = this.billEntitiesToPay.filter(el =>  el.id != elFound.id)
      }
      this.quantityPaid--
      elNotToPay.quantityBeingPaid--
      this.bus.$emit("reload-items", this.billEntitiesToPay);
    },
    setDiscountBar() {
      billBarCalculator.setGeneralDiscountBar({
        type: this.discountBar.editPriceType,
        value: this.discountBar.editPriceValue
      })
      this.discountGeneralDialog = false
    },
    removeDiscountBar() {
      billBarCalculator.setGeneralDiscountBar({
        type: undefined,
        value: undefined
      });
      this.discountBar = {
        editPriceType: undefined,
        editPriceValue: undefined
      }
      this.discountGeneralDialog = false
    },
    addDiscount(entity){
      this.discountDialog = true
      this.entityDetail = entity
      
      if(!!entity.serviceId){
        this.type = 'service'
      }
      else{
        this.type = 'item'
      }

      this.discountItem = {
        'editPriceValue': entity.editPriceValue,
        'editPriceType': entity.editPriceType,
      }
    },
    setDiscount() {
      let localValue = this.discountItem["editPriceValue"]
      let localType = this.discountItem["editPriceType"]
      if (this.discountItem["mode"] == 1)
        localValue = -localValue

      if(this.type == 'service')
        billService.updateBillServiceById({
          ...this.entityDetail,
          'editPriceValue': localValue,
          'editPriceType': localType
        }).then(() => {
          this.entityDetail.editPriceValue = localValue
          this.entityDetail.editPriceType = localType
          this.reloadItem(this.entityDetail)
        })
      else
        billService.updateBillItemById({
          ...this.entityDetail,          
          'editPriceValue': localValue,
          'editPriceType': localType
        }).then(() => {
          this.entityDetail.editPriceValue = localValue
          this.entityDetail.editPriceType = localType
          this.reloadItem(this.entityDetail)
        })
      this.discountDialog = false
    },
    removeDiscount(){
      if(this.type == 'service')
        billService.updateBillServiceById({
          ...this.entityDetail,
        'editPriceType': null,
        'editPriceValue': null,
        }).then(() => {
          this.entityDetail.editPriceValue = null
          this.entityDetail.editPriceType = null
          this.reloadItem(this.entityDetail)
        })
      else
        billService.updateBillItemById({
          ...this.entityDetail,
        'editPriceType': null,
        'editPriceValue': null,
        }).then(() => {
          this.entityDetail.editPriceValue = null
          this.entityDetail.editPriceType = null
          this.reloadItem(this.entityDetail)
        })
      this.discountDialog = false
    },
    handleDiscountFormValid(valid) {
      this.discountFormValid = valid
    },
    handleDiscountBarFormValid(valid) {
      this.discountBarFormValid = valid
    },
    openDiscountGeneralDialog() {
      if (!!this.partialPayment || this.billEntities.filter(entity => entity.paidQuantity > 0).length > 0)
        this.discountGeneralAlertText = 'Attenzione!  Non è possibile assegnare uno sconto generale su conti parziali.'
      else {
        this.discountGeneralAlertText = 'Lo sconto generale a valore verrà ripartito partire dal prodotto/servizio con prezzo più alto.'
        this.closeDiscountGeneralAlertWithoutControl = false
      }
      this.discountGeneralAlert = true
    },
    closePopUp() {
      if (!!this.partialPayment || this.billEntities.filter(entity => entity.paidQuantity > 0).length > 0
        || this.closeDiscountGeneralAlertWithoutControl)
        this.discountGeneralAlert = false
      else
        this.discountGeneralDialog = true
    },
    reloadItem(item){
      const elFound = this.billEntitiesToPay.find(el => el.id == item.id)
      if(!!elFound){
        elFound.editPriceValue = item.editPriceValue
        elFound.editPriceType = item.editPriceType  
      }
      this.bus.$emit("reload-items", this.billEntitiesToPay);
    },
    calculateNewPriceService(service, price) {
      const localPrice = !!price ? Number(price) : Number(service.service.price)

      if (!!service.editPriceType && !!service.editPriceValue) {
        if (service.editPriceType == 'number') {
          let percentageFromOtherDiscount = !!price ? (Number(price) * 100) / Number(service.service.price) : 0
          return percentageFromOtherDiscount > 0 ? (localPrice - ((percentageFromOtherDiscount * Number(service.editPriceValue)) / 100)).toFixed(2)
            : (localPrice - Number(service.editPriceValue)).toFixed(2)
        }
        else if (service.editPriceType == 'percentage')
          return (localPrice - (localPrice * (service.editPriceValue / 100))).toFixed(2)
      }
      else {
        return localPrice
      }
    },
    calculateNewPriceItem(item, price) {
      const localPriceItem = !!price ? Number(price) : Number(item.item.priceSelling)
      if (!!item.editPriceType && !!item.editPriceValue) {
        if (item.editPriceType == 'number') {
          let percentageFromOtherDiscount = !!price ? (Number(price) * 100) / Number(item.item.priceSelling) : 0
          return percentageFromOtherDiscount > 0 ? (localPriceItem - ((percentageFromOtherDiscount * Number(item.editPriceValue)) / 100)).toFixed(2)
            : (localPriceItem - Number(item.editPriceValue)).toFixed(2)
        }
        else if (item.editPriceType == 'percentage')
          return (localPriceItem - (localPriceItem * (item.editPriceValue / 100))).toFixed(2)
      }
      else {
        return localPriceItem
      }
    },
    handleItemGhost(item) {
      if (item.paidQuantity === Number(item.quantity)) {
        return
      }

      if (item.paidQuantity > 0) {
        this.partialPaymentAlert = true
        return
      }
      this.loadingListItemComponent = true
      if(Number(item.quantityBeingPaid) > 0) {
        this.loadingListItemComponent = false
        return
      }

      if(!item.itemId) {
        if(item.ghost) {
          billService.unghostService(item, item.quantity).then(async () => {
            await this.fetchBillEntities()
            this.loadingListItemComponent = false
          })
        } else {
          billService.ghostService(item, item.quantity).then(async () => {
            await this.fetchBillEntities()
            this.loadingListItemComponent = false
          })
        }
      } else {
        if(item.ghost) {
          billService.unghostItem(item, item.quantity).then(async () => {
            await this.fetchBillEntities()
            this.loadingListItemComponent = false
          })
        } else {
          billService.ghostItem(item, item.quantity).then(async () => {
            await this.fetchBillEntities()
            this.loadingListItemComponent = false
          })
        }
      }
    },
    async fetchBillInformation(billId) {
      return new Promise((resolve, reject) => {
        billService.get({ id: billId })
          .then((bill) => {
            if (!bill)
              return resolve(null)
            resolve({ 
              documentType: bill.documentType,
              customers: bill.billCustomers
            })
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    }
  },
  watch:{
    openMenu(newVal){
      this.localOpenMenu = newVal
    },
    billId(newVal){
      if(!!newVal){
        this.loadingBill = true
        this.fetchBillInformation(newVal).then(async (billInfo) => {
          this.$nextTick(() => {
            this.bus.$emit("reload-bill", { 
              id: this.billId, 
              documentType: billInfo.documentType,
              customers: billInfo.customers
            });
          })
          await this.fetchBillEntities()
          this.loadingBill = false
        })
      }
    },
    selectedTable(newVal){
      !!newVal ? this.reservationTitle = "Comanda tavolo " + this.selectedTable.i : this.reservationTitle = 'Cliente Occaisonale'
    }
  },
  computed:{
    partialPayment(){
      return (this.quantityPaid < this.quantityOrdered)
    },
    maxPrice(){
      return this.billEntitiesToPay.reduce((acc, el) => {
        if(el.itemId) {
          if (!!el.editPriceType && !!el.editPriceValue) {
            if (el.editPriceType == 'number') {
              let percentageFromOtherDiscount = (el.item.priceSelling * 100) / el.item.priceSelling
              return percentageFromOtherDiscount > 0 ? acc + (el.item.priceSelling - ((percentageFromOtherDiscount * el.editPriceValue) / 100))
                : acc + (el.item.priceSelling - el.editPriceValue)
            }
            else if (el.editPriceType == 'percentage')
              return acc + ((el.item.priceSelling - (el.item.priceSelling * (el.editPriceValue / 100))) * el.quantity)
          }
          else
            return acc + (el.item.priceSelling * el.quantity)
        }
        else {
          if (!!el.editPriceType && !!el.editPriceValue) {
            if (el.editPriceType == 'number') {
              let percentageFromOtherDiscount = (el.service.price * 100) / el.service.price
              return percentageFromOtherDiscount > 0 ? acc + (el.service.price - ((percentageFromOtherDiscount * el.editPriceValue) / 100))
                : acc + (el.service.price - el.editPriceValue)
            }
            else if (el.editPriceType == 'percentage')
              return acc + ((el.service.price - (el.service.price * (el.editPriceValue / 100))) * el.quantity)
          }
          else
            return acc + (el.service.price * el.quantity)
        }
      }, 0)
    },
  },
  settings: {
    taxForBar: {
      bind: 'taxForBar'
    }
  }
};
</script>

<style>
</style>