var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"overflow-y":"clip"}},[_c('div',{staticClass:"d-flex first-row",class:{
      'flex-column': _vm.$vuetify.breakpoint.height < 500,
      'flex-wrap': _vm.$vuetify.breakpoint.height > 500
    }},[_c('div',{staticClass:"pa-2 pl-5 pt-3 list-section-container"},[_c('v-card',{staticStyle:{"height":"100%"}},[_c('CustomersListSection',{staticStyle:{"height":"100%","width":"100%"},attrs:{"add-customer-dialog":_vm.localAddCustomerDialog,"customer-from-voice":_vm.customerFromVoice,"bus":_vm.homepageBus,"disabled":_vm.isLicenseBlocked || _vm.isOnlyWheelOfFortune},on:{"update:addCustomerDialog":function($event){_vm.localAddCustomerDialog=$event},"update:add-customer-dialog":function($event){_vm.localAddCustomerDialog=$event}},model:{value:(_vm.localSelectedBill),callback:function ($$v) {_vm.localSelectedBill=$$v},expression:"localSelectedBill"}})],1)],1),_c('div',{staticClass:"d-flex pa-2 pt-3 service-selector-container"},[_c('v-card',{attrs:{"color":"white"}},[_c('ServiceSelector',{staticStyle:{"max-height":"100%","height":"100%"},attrs:{"mandatory":"","customer":!!_vm.localSelectedBill ? _vm.localSelectedBill.customer : undefined,"tooltip-delay":1750,"show-frequent-services":""},on:{"click":_vm.handleServiceSelection}})],1)],1)]),_c('div',{staticClass:"d-flex second-row",class:{
      'flex-column': _vm.$vuetify.breakpoint.height < 500,
      'flex-wrap': _vm.$vuetify.breakpoint.height > 500
    }},[_c('div',{staticClass:"pa-2 pl-5 pb-3 customer-summary-section",class:{
        'customer-summary-section-no-customer': (!!this.localSelectedBill && !this.localSelectedBill.customer) || !this.localSelectedBill,
        //'customer-summary-section': !!this.localSelectedBill && !!this.localSelectedBill.customer
      }},[_c('CustomersSummarySection',{staticStyle:{"max-height":"47.5vh","height":"48.5vh"},attrs:{"customer":!!_vm.localSelectedBill ? _vm.localSelectedBill.customer : undefined,"bill":_vm.localSelectedBill,"provided-services":_vm.providedServices,"provided-items":_vm.providedItems,"bus":_vm.homepageBus,"scanning":_vm.customerSummarySectionScanning,"card-detail-dialog":_vm.localOpenCardDetailDialog,"selected-services":_vm.selectedServices,"disabled":_vm.cashSectionScanning},on:{"update:providedServices":function($event){_vm.providedServices=$event},"update:provided-services":function($event){_vm.providedServices=$event},"update:providedItems":function($event){_vm.providedItems=$event},"update:provided-items":function($event){_vm.providedItems=$event},"update:scanning":function($event){_vm.customerSummarySectionScanning=$event},"update:cardDetailDialog":function($event){_vm.localOpenCardDetailDialog=$event},"update:card-detail-dialog":[function($event){_vm.localOpenCardDetailDialog=$event},function($event){return this.$emit('update:openCardDetailDialog', $event)}],"update:customer":_vm.handleCustomerUpdate}})],1),_c('div',{staticClass:"pa-2 pb-2 operators-list-section",class:{
        'operators-list-section-no-customer': (!!this.localSelectedBill && !this.localSelectedBill.customer) || !this.localSelectedBill,
        //'operators-list-section': !!this.localSelectedBill && !!this.localSelectedBill.customer
      }},[_c('OperatorsListSection',{staticStyle:{"max-height":"47.5vh"},attrs:{"elevated":""},on:{"click":_vm.handleOperatorSelection}})],1),_c('div',{staticClass:"pa-2 pb-3 cash-section",class:{
        'cash-section-no-customer': (!!this.localSelectedBill && !this.localSelectedBill.customer) || !this.localSelectedBill,
        //'cash-section': !!this.localSelectedBill && !!this.localSelectedBill.customer
      },staticStyle:{"max-height":"100%"}},[_c('CashSection',{attrs:{"selected-services":_vm.selectedServices,"bus":_vm.homepageBus,"bill":_vm.completeBill,"scanning":_vm.cashSectionScanning,"disabled":_vm.customerSummarySectionScanning || _vm.isLicenseBlocked || _vm.isOnlyWheelOfFortune},on:{"update:scanning":function($event){_vm.cashSectionScanning=$event}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }