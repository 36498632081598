<template>
  <div style="height: 100%">
    <v-card height="100%" max-height="100%" width="100%" class="d-flex justify-center align-center" flat
      color="transparent" :style="{ 'display': customerSelected ? 'none !important' : undefined }">
      <v-card-title class="text-caption font-weight-light">
        Seleziona un cliente
      </v-card-title>
    </v-card>
    <v-card :style="{ 'display': customerSelected ? undefined : 'none !important' }" height="100%" max-height="100%"
      width="100%" class="d-flex flex-column">
      <v-card-title class="d-flex">
        <div>{{ customerSelected ? `${localCustomer.lastname} ${localCustomer.firstname}` : undefined }}</div>
        <div class="ml-auto">
          <v-tooltip allow-overflow top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-on="on" v-bind="attrs" class="mr-3" @click="toggleAvatarDialog">
                <v-badge content="1" :value="!!localCustomer && !!localCustomer.avatar">
                  <v-icon>mdi-camera</v-icon>
                </v-badge>
              </v-btn>
            </template>
            <span>Avatar Cliente</span>
          </v-tooltip>
          <v-tooltip allow-overflow top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-on="on" v-bind="attrs" class="mr-3" @click="openHistoryDashboardDialog">
                <v-icon>mdi-view-dashboard</v-icon>
              </v-btn>
            </template>
            <span>Visualizza storico cliente</span>
          </v-tooltip>
          <v-tooltip allow-overflow top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-on="on" v-bind="attrs" class="mr-3" @click="openScanItemDialog">
                <v-icon>mdi-barcode-scan</v-icon>
              </v-btn>
            </template>
            <span>Scannerizza prodotti da aggiungere al conto</span>
          </v-tooltip>
          <v-tooltip allow-overflow top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-on="on" v-bind="attrs" class="mr-3" @click="openPaymentCreditsView">
                <v-badge :content="countPaymentCredits" :value="!!countPaymentCredits">
                  <v-icon>mdi-bank</v-icon>
                </v-badge>
              </v-btn>
            </template>
            <span>Visualizza debiti con il negozio</span>
          </v-tooltip>
          <v-tooltip allow-overflow top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-on="on" v-bind="attrs" class="mr-3" @click="openCustomerCardView">
                <v-icon>mdi-card-account-details-outline</v-icon>
              </v-btn>
            </template>
            <span>Visualizza carta cliente</span>
          </v-tooltip>
          <v-tooltip allow-overflow top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-on="on" v-bind="attrs" class="mr-3" @click="openPromoDialog">
                <v-badge :content="promosNumber" :value="!!promosNumber">
                  <v-icon>mdi-tag-text</v-icon>
                </v-badge>
              </v-btn>
            </template>
            <span>Visualizza e assegna le promozioni</span>
          </v-tooltip>
        </div>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0" >
        <TypeDataTable 
          v-model="selectedService" 
          :headers="headers" 
          :items="filteredPrividedEverything" 
          item-key="idKey"
          fixed-header 
          height="calc(50vh - 170px)" 
          disable-pagination 
          hide-default-footer 
          :show-select="false"
          :single-select="true" 
          @dblclick:row="handleDoubleClickRow"
          :loading="loading"
          >
          <template v-slot:custom-name="{ item }">
            <v-badge :value="item.ghost" :content="!!item.ghostQuantity ? item.ghostQuantity : 1" inline color="error"
              v-if="item.type == 'service'">
              <div>{{ item.service.name }}</div>
            </v-badge>
            <v-badge :value="item.ghost" :content="!!item.ghostQuantity ? item.ghostQuantity : 1" inline color="error"
              v-if="item.type == 'item'">
              <div>{{ item.item.description }}</div>
            </v-badge>
          </template>
          <template v-slot:custom-operators="{ item }">
            <span v-if="!!item['billServiceOperators'] && item['billServiceOperators'].length != 0">
              <OperatorChip v-for="billServiceOperator in item['billServiceOperators']" close
                :value="billServiceOperator.operator" :key="billServiceOperator.id" class="ma-1" show-only-name-operator
                @close="removeOperator(item, $event)" small></OperatorChip>
            </span>
            <span v-if="item.type == 'item'">
              <OperatorChip v-for="billItemOperator in item['billItemOperators']" close
                :value="billItemOperator.operator" :key="billItemOperator.id" class="ma-1" show-only-name-operator
                @close="removeOperator(item, $event)" small></OperatorChip>
            </span>
          </template>
          <template v-slot:custom-price="{ item }">
            <span v-if="item.type == 'service'" style="white-space: nowrap">
              {{ calculateNewPrice(item).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }}
            </span>
            <span v-if="item.type == 'item'" style="white-space: nowrap">
              {{ calculateNewPriceItem(item).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }}
            </span>
          </template>
          <template v-slot:custom-discount-perc="{ item }">
            <span v-if="item.type == 'service' && !!item.service.price">
              {{ calculateNewPrice(item) != 0 ?
              (100 - (Number(calculateNewPrice(item, item.discount)) * 100 /
              Number(calculateNewPrice(item)))).toFixed(2)
              : 0
              }}%
            </span>
            <span v-else-if="item.type == 'item' && !!item.item.priceSelling">
              {{ calculateNewPriceItem(item) != 0 ?
              (100 - (Number(calculateNewPriceItem(item, item.discount)) * 100 /
              Number(calculateNewPriceItem(item)))).toFixed(2)
              : 0
              }}%
            </span>
            <span v-else>
              Non specificato
            </span>
          </template>
          <template v-slot:custom-discount="{ item }">
            <span v-if="item.type == 'service' && !!item.service.price">
              {{ !!item.discount ? (Number(calculateNewPrice(item)) - Number(calculateNewPrice(item,
              item.discount))).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) : (0).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
              }}
            </span>
            <span v-else-if="item.type == 'item' && !!item.item.priceSelling">
              {{ !!item.discount ? (Number(calculateNewPriceItem(item)) - Number(calculateNewPriceItem(item,
              item.discount))).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) : (0).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
              }}
            </span>
            <span v-else>
              Non specificato
            </span>
          </template>
          <template v-slot:custom-totalPrice="{ item }" >
             <span v-if="item.type == 'service' && !!item.service.price" style="white-space: nowrap">
              {{ Number(calculateNewPrice(item, item.discount).toFixed(2) * item.quantity).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
              }}
            </span>
            <span v-else-if="item.type == 'item' && !!item.item.priceSelling" style="white-space: nowrap">
              {{ Number(calculateNewPriceItem(item, item.discount).toFixed(2) * item.quantity).toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })
              }}
            </span>
            <span v-else>
              Non specificato
            </span>
          </template>
          <template v-slot:custom-isCompleted="{ item }">
            <div class="d-flex align-center justify-center">
              <v-checkbox class="px-0" v-model="item.isCompleted" @click.stop.prevent="" @change="setIsCompleted(item)"></v-checkbox>
            </div>
          </template>
          <template v-slot:actions="{ item }">
            <div class="d-flex align-center justify-center">
              <!-- <v-btn
                icon
                @click.stop="removeService(item.service)"
                :disabled="parseInt(item.quantity) <= 1"
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn> -->
              <v-btn icon small @click.stop="deleteElement(item)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn icon small v-if="item.service" @click.stop="showServiceDetail(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon small v-if="item.item" @click.stop="showItemDetail(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </template>
        </TypeDataTable>
      </v-card-text>
      <v-card-actions class="mt-auto">
        <v-spacer></v-spacer>
        <v-btn text @click="deleteAllElements">
          Resetta
        </v-btn>
      </v-card-actions>
    </v-card>

    <StandardDialog 
      v-model="serviceDetailDialog" 
      dialog-height="80vh" 
      dialog-max-height="80vh" 
      :title="serviceDetailDialogTitle"
      dialog-max-width="1300px"
      @calc-body-height="(val) => { serviceDetailBodyHeight = val }"
    >
      <template v-slot:header-actions v-if="!!serviceDetail">
        <v-tooltip allow-overflow top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" class="ml-2" @click.stop="openDiscountServiceDialog">
              <v-icon>mdi-sale</v-icon>
            </v-btn>
          </template>
          <span>Assegna Sconto</span>
        </v-tooltip>
        <v-tooltip allow-overflow top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" class="ml-2" @click.stop="openEditServicePriceDialog">
              <v-icon>mdi-pencil-box</v-icon>
            </v-btn>
          </template>
          <span>Modifica Prezzo</span>
        </v-tooltip>
        <v-text-field v-model="serviceDetailQuantity" style="width: 10%; flex: none; margin-left: 5px" type="number"
          min="1" dense label="Quantità" hide-details="auto"></v-text-field>
        <v-tooltip allow-overflow top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" class="ml-2" :disabled="loading"
              @click.stop="addService(serviceDetail.service, serviceDetailQuantity)">
              <v-icon>mdi-cart-arrow-down</v-icon>
            </v-btn>
          </template>
          <span>Aggiungi una quantità</span>
        </v-tooltip>
        <v-tooltip allow-overflow top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" class="ml-2"
              @click.stop="removeService(serviceDetail.service, serviceDetailQuantity)"
              :disabled="parseInt(serviceDetail.quantity) <= 1 || loading">
              <v-icon>mdi-cart-remove</v-icon>
            </v-btn>
          </template>
          <span>Rimuovi una quantità</span>
        </v-tooltip>
      </template>
      <v-row class="pt-2" :style="{height: serviceDetailBodyHeight}" v-if="!!serviceDetail">
        <v-col cols="3" style="height: 100%" class="d-flex flex-column justify-start align-center">
          <v-icon size="80">{{ serviceDetail.service.icon }}</v-icon>
          <div class="text-h6">
            {{ serviceDetail.service.name }}
          </div>
          <div class="text-caption">
            {{ serviceDetail.service.description }}
          </div>
          <div class="text-overline mt-4" style="font-size: 20pt !important">{{ !!serviceDetail.service.price ?
          `${serviceDetail.service.price} €` : undefined
          }}</div>
          <div class="mt-2">
            <span class="font-weight-bold mr-2">Quantità:</span>
            <span class="">{{ serviceDetail.quantity }}</span>
          </div>
          <div v-if="!!serviceDetail.editPriceValue && !!serviceDetail.editPriceType" style="width: 100%">
            <v-divider class="mt-3"></v-divider>
            <!--             <div class="d-flex align-center justify-center mt-3 mr-4">
              <span class="font-weight-bold mr-4">Quantità Scontate:</span>
              <span class="">{{serviceDetail.editPriceQuantity}}</span>
            </div> -->
            <div class="d-flex align-center justify-center">
              <span class="font-weight-bold mr-2 mt-2">Prezzo Scontato:</span>
              <span class="mt-2">{{ calculateNewPrice(serviceDetail, serviceDetail.discount) }} €</span>
            </div>
          </div>
          <div style="width: 100%">
            <v-divider class="mt-3"></v-divider>
            <div class="mt-3 d-flex align-center justify-center">
              <span class="text-h6 mr-1">Totale</span>
            </div>
            <div class="d-flex align-center justify-center mt-3" style="font-size: 20pt !important">
              {{ calculateTotalPriceWithDiscount(serviceDetail) }} €
            </div>
          </div>
        </v-col>
        <v-col style="height: 100%" class="pt-7" cols="6">
          <v-row>
            <v-col>
              <v-data-table :headers="[
                { text: '', value: 'icon', width: 50 },
                { text: 'Operatore', value: 'name' },
                { text: 'Quantità', value: 'quantity' },
                { text: '', value: 'actions', sortable: false }
              ]" :items="detailsOperators" disable-pagination hide-default-footer>
                <template v-slot:item.icon="{ item }">
                  <OperatorAvatar :value="item.operator" :size="20"></OperatorAvatar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex align-center justify-center">
                    <v-btn icon :disabled="loading"
                      @click="updateOperatorOfServiceDetail(serviceDetail, item.operator, (parseInt(item.quantity) + 1))">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn icon :disabled="loading"
                      @click="updateOperatorOfServiceDetail(serviceDetail, item.operator, (parseInt(item.quantity) - 1))">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
        <v-col style="height: 100%" class="" cols="3">
          <OperatorsListSection :loading="loading" @click="addOperatorToServiceDetail"></OperatorsListSection>
        </v-col>
      </v-row>
    </StandardDialog>

    <StandardDialog 
      v-model="itemDetailDialog" 
      dialog-height="80vh" 
      dialog-max-height="80vh" 
      :title="itemDetailDialogTitle"
      dialog-max-width="1300px"
      @calc-body-height="(val) => { itemDetailBodyHeight = val }"
    >
      <v-snackbar
        v-model="notEnoughQuantitySnackbarFromDialog"
        :timeout="6000"
        absolute
        vertical
        bottom
        elevation="24"
      >
        Quantità non disponibile per l'articolo! Fare un movimento di magazzino.
        <template v-slot:action="{ attrs }">
          <v-btn
            color="rgb(80, 191, 225, 0.9)"
            v-bind="attrs"
            outlined
            @click="$router.push({ name: 'MovementUploadList' })"
          >
            Vai al Magazzino
          </v-btn>
        </template>
      </v-snackbar>
      <template v-slot:header-actions v-if="!!itemDetail">
        <v-tooltip allow-overflow top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" class="ml-2" @click.stop="openDiscountItemDialog">
              <v-icon>mdi-sale</v-icon>
            </v-btn>
          </template>
          <span>Assegna Sconto</span>
        </v-tooltip>
        <v-tooltip allow-overflow top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" class="ml-2" @click.stop="openEditItemPriceDialog">
              <v-icon>mdi-pencil-box</v-icon>
            </v-btn>
          </template>
          <span>Modifica Prezzo</span>
        </v-tooltip>
        <v-text-field v-model="itemDetailQuantity" style="width: 10%; flex: none; margin-left: 5px" type="number"
          min="1" dense label="Quantità" hide-details="auto"></v-text-field>
        <v-tooltip allow-overflow top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" class="ml-2" :disabled="loading"
              @click.stop="addItem(itemDetail.item, itemDetailQuantity, true)">
              <v-icon>mdi-cart-arrow-down</v-icon>
            </v-btn>
          </template>
          <span>Aggiungi una quantità</span>
        </v-tooltip>
        <v-tooltip allow-overflow top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-on="on" v-bind="attrs" class="ml-2"
              @click.stop="removeItem(itemDetail.item, itemDetailQuantity)"
              :disabled="parseInt(itemDetail.quantity) <= 1 || loading">
              <v-icon>mdi-cart-remove</v-icon>
            </v-btn>
          </template>
          <span>Rimuovi una quantità</span>
        </v-tooltip>
      </template>
      <v-row class="pt-2" :style="{height: itemDetailBodyHeight}" v-if="!!itemDetail">
        <v-col cols="3" style="height: 100%" class="d-flex flex-column justify-start align-center">
          <v-icon size="80">{{ itemDetail.item.icon }}</v-icon>
          <div class="text-h6">
            {{ itemDetail.item.description }}
          </div>
          <div class="text-overline mt-4" style="font-size: 20pt !important">{{ !!itemDetail.item.priceSelling ?
          `${itemDetail.item.priceSelling} €` : undefined
          }}</div>
          <div class="mt-2">
            <span class="font-weight-bold mr-2">Quantità:</span>
            <span class="">{{ itemDetail.quantity }}</span>
          </div>
          <div v-if="!!itemDetail.editPriceValue && !!itemDetail.editPriceType" style="width: 100%">
            <v-divider class="mt-3"></v-divider>
            <!--             <div class="d-flex align-center justify-center mt-3 mr-4">
              <span class="font-weight-bold mr-4">Quantità Scontate:</span>
              <span class="">{{serviceDetail.editPriceQuantity}}</span>
            </div> -->
            <div class="d-flex align-center justify-center">
              <span class="font-weight-bold mr-2 mt-2">Prezzo Scontato:</span>
              <span class="mt-2">{{ calculateNewPriceItem(itemDetail, itemDetail.discount) }} €</span>
            </div>
          </div>
          <div style="width: 100%">
            <v-divider class="mt-3"></v-divider>
            <div class="mt-3 d-flex align-center justify-center">
              <span class="text-h6 mr-1">Totale</span>
            </div>
            <div class="d-flex align-center justify-center mt-3" style="font-size: 20pt !important">
              {{ calculateTotalPriceItemWithDiscount(itemDetail) }} €
            </div>
          </div>
        </v-col>
        <v-col style="height: 100%" class="pt-7" cols="6">
          <v-row>
            <v-col>
              <v-data-table :headers="[
                { text: '', value: 'icon', width: 50 },
                { text: 'Operatore', value: 'name' },
                { text: 'Quantità', value: 'quantity' },
                { text: '', value: 'actions', sortable: false }
              ]" :items="detailsOperators" disable-pagination hide-default-footer>
                <template v-slot:item.icon="{ item }">
                  <OperatorAvatar :value="item.operator" :size="20"></OperatorAvatar>
                </template>
                <template v-slot:item.actions="{ item }">
                  <div class="d-flex align-center justify-center">
                    <v-btn icon :disabled="loading"
                      @click="updateOperatorOfItemDetail(itemDetail, item.operator, (parseInt(item.quantity) + 1))">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn icon :disabled="loading"
                      @click="updateOperatorOfItemDetail(itemDetail, item.operator, (parseInt(item.quantity) - 1))">
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <v-btn icon @click="removeOperator(itemDetail, item.operator)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
        <v-col style="height: 100%" class="" cols="3">
          <OperatorsListSection :loading="loading" @click="addOperatorToItemDetail"></OperatorsListSection>
        </v-col>
      </v-row>
    </StandardDialog>

    <DiscountForm v-model="servicePriceEdited" :open-dialog.sync="editServicePriceDialog" :entity-detail="serviceDetail"
      title="Modifica Prezzo" :permission.sync="showAlertDiscountPermission" type="service" price-mode="edit"
      @set-discount="editPrice" @remove-discount="resetPrice"></DiscountForm>

    <DiscountForm v-model="itemPriceEdited" :open-dialog.sync="editItemPriceDialog" :entity-detail="itemDetail"
      title="Modifica Prezzo" :permission.sync="showAlertDiscountPermission" type="item" price-mode="edit"
      @set-discount="editPrice" @remove-discount="resetPrice"></DiscountForm>

    <DiscountForm v-model="discountService" :open-dialog.sync="discountServiceDialog" :entity-detail="serviceDetail"
      title="Assegna Sconto" :permission.sync="showAlertDiscountPermission" type="service" price-mode="discount"
      :modes="[{ key: 0, value: 'Sconto' }]" @set-discount="setDiscount" @remove-discount="removeDiscount">
    </DiscountForm>

    <DiscountForm v-model="discountItem" :open-dialog.sync="discountItemDialog" :entity-detail="itemDetail"
      title="Assegna Sconto" :permission.sync="showAlertDiscountPermission" type="item" price-mode="discount"
      :modes="[{ key: 0, value: 'Sconto' }]" @set-discount="setDiscount" @remove-discount="removeDiscount">
    </DiscountForm>

    <StandardDialog v-model="customerCardDetailDialog" :dialog-height="null" title="Carta Cliente"
      dialog-max-width="1000px" @input="$emit('update:cardDetailDialog', $event)">
      <CustomerCardForm class="mt-4" v-model="customerCard" show-load-button :scanning.sync="customerCardScanning"
        :customer="localCustomer" @confirm-load="handleLoadConfirmed" @input="emitCustomerInput"></CustomerCardForm>
    </StandardDialog>

    <StandardDialog v-model="paymentCreditDetailDialog" :dialog-height="null" title="Crediti Cliente"
      dialog-max-width="1000px">
      <PaymentCreditsForm :customer="localCustomer"></PaymentCreditsForm>
    </StandardDialog>

    <StandardDialog v-model="scanItemDialog" :dialog-height="null" title="Scannerizza prodotti"
      dialog-max-width="500px">
      <ManualBarcodeInput v-model="insertedItemBarcode" @confirm="handleConfirmItemBarcode"
        scan-your-barcode-message="Scannerizza i prodotti ..." type=""></ManualBarcodeInput>
    </StandardDialog>

    <StandardDialog v-model="showErrorQuantityAddItem" title="Quantità A Magazzino Non Sufficiente"
      :dialog-height="null" dialog-width="100vh">
      <v-alert class="ma-2" dense outlined type="error">
        Rispetto alla quantità inserita nel conto, a magazzino mancano {{ qtyOverflow }} unità
      </v-alert>
    </StandardDialog>

    <StandardDialog v-model="promoDialog" :dialog-height="null" title="Promo Cliente" dialog-max-width="1000px">
      <CustomerPromosForm 
        v-if="promoDialog" 
        :customer="localCustomer" 
        :promos-customers.sync="promoCustomers"
        :bus="bus"
        @update:promos-customers="handleUpdatePromoCustomers"
      ></CustomerPromosForm>
      <template v-slot:footer-actions>
        <v-btn text color="error" @click="closePromos">Chiudi</v-btn>
      </template>
    </StandardDialog>

    <StandardDialog 
      v-model="historyDashboardDialog" 
      :dialog-height="null" 
      title="Dashboard Storico Cliente"
      dialog-max-width="90vw" style="overflow-y: hidden"
      
    >
      <template v-slot:header-actions>
        <v-btn 
          icon 
          large 
          style="float: right; margin-right: 2.5%"
          :color="!!isHistoryDashboardB ? 'red' : ''"
          @click="() => { historyDashboardDialogBarcode = !historyDashboardDialogBarcode }"
        >
          <v-icon>mdi-shield-account-variant</v-icon>
        </v-btn>
      </template>
      <CustomerHistoryDashboardForm 
        v-if="historyDashboardDialog" 
        :customer="localCustomer" 
        :items="localProvidedItems"
        :bus="bus"
      >
      </CustomerHistoryDashboardForm>

      <template v-slot:footer-actions>
        <v-btn 
          text 
          color="error" 
          v-on:click="() => { 
            historyDashboardDialog = false; 
            isHistoryDashboardB = false;
          }"
        >
          Chiudi
        </v-btn>
      </template>
    </StandardDialog>

    <StandardDialog 
      v-model="historyDashboardDialogBarcode" 
      title="Area Riservata Sviluppatori" 
      :dialog-height="null" 
      dialog-max-width="500px"
    >
      <ManualBarcodeInput 
        v-model="operatorBarcode" 
        scan-your-barcode-message="Immettere Codice" 
        @confirm="activeBarcode">
      </ManualBarcodeInput>
    </StandardDialog>

    <StandardDialog v-model="avatarDialog" title="Avatar Cliente" dialog-width="500px" dialog-height="560px">
      <div>
        <div v-show="!cameraLoading" class="ma-0 mt-2">
          <video class="rounded-lg" v-show="!isPhotoTaken && !avatarExists" ref="camera" :width="450" :height="337.5"
            autoplay></video>

          <canvas class="rounded-lg" v-show="isPhotoTaken && !avatarExists" id="photoTaken" ref="canvas" :width="450"
            :height="337.5"></canvas>

          <v-img class="rounded-lg mb-2" v-show="avatarExists" id="avatar" :width="450" :height="337.5"
            :src="avatarUrl"></v-img>
        </div>

        <div v-if="!cameraLoading && !errorCamera" class="d-flex justify-center">
          <v-btn color="primary" x-large elevation4 fab @click="takePhoto">
            <a id="downloadPhoto" download="my-photo.jpg"></a>
            <v-icon x-large color="white">{{avatarExists ? 'mdi-trash-can' : 'mdi-camera'}}</v-icon>
          </v-btn>
        </div>
      </div>

      <template v-slot:footer-actions>
        <v-spacer></v-spacer>
        <v-btn text color="error" @click="toggleAvatarDialog">
          Chiudi
        </v-btn>
        <v-btn text color="default" :loading="loadingPhotoUpload" :disabled="!isPhotoTaken" @click="uploadAvatar">Salva
        </v-btn>
      </template>
    </StandardDialog>

    <StandardDialog v-model="itemsNotFoundDialog" :dialog-height="null" title="Errore Codice A Barre"
      dialog-max-width="1000px">
      <v-alert class="mt-5" type="error" outlined> {{ 'Codice Non Trovato!' }}
      </v-alert>
    </StandardDialog>

    <v-bottom-sheet v-model="ghostServiceBottomSheet" dark width="100vw">
      <v-sheet class="d-flex align-center justify-center flex-column" max-height="50vh" height="200px">
        <div>
          <v-text-field v-model="ghostServiceQuantity" filled dense label="Quantità" hide-details="auto"></v-text-field>
        </div>
        <div class="d-flex justify-center align-center mt-5">
          <v-btn text color="error"
            @click="ghostServiceBottomSheet = false; ghostingBillService = undefined; ghostServiceQuantity = undefined">
            <v-icon left>mdi-close</v-icon>
            Chiudi
          </v-btn>
          <v-btn text color="default" :disabled="!ghostServiceQuantity" @click="confirmGhost">
            <v-icon left>mdi-check</v-icon>
            Conferma
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>

    <v-bottom-sheet v-model="ghostItemBottomSheet" dark width="100vw">
      <v-sheet class="d-flex align-center justify-center flex-column" max-height="50vh" height="200px">
        <div>
          <v-text-field v-model="ghostItemQuantity" filled dense label="Quantità" hide-details="auto"></v-text-field>
        </div>
        <div class="d-flex justify-center align-center mt-5">
          <v-btn text color="error"
            @click="ghostItemBottomSheet = false; ghostingBillItem = undefined; ghostItemQuantity = undefined">
            <v-icon left>mdi-close</v-icon>
            Chiudi
          </v-btn>
          <v-btn text color="default" :disabled="!ghostItemQuantity" @click="confirmGhostItem">
            <v-icon left>mdi-check</v-icon>
            Conferma
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <v-snackbar
      v-model="notEnoughQuantitySnackbar"
      :timeout="6000"
      absolute
      vertical
      bottom
      elevation="24"
    >
      Quantità non disponibile per l'articolo! Fare un movimento di magazzino.
      <template v-slot:action="{ attrs }">
        <v-btn
          color="rgb(80, 191, 225, 0.9)"
          v-bind="attrs"
          outlined
          @click="$router.push({ name: 'MovementUploadList' })"
        >
          Vai al Magazzino
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import OperatorChip from '@/components/common/OperatorChip'
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import billService from '@/services/bills/bills.service.js'
import StandardDialog from '@/components/common/StandardDialog.vue'
import OperatorAvatar from '@/components/common/OperatorAvatar.vue'
import CustomerCardForm from '@/components/areas/registries/customers/customerCard/CustomerCardForm.vue'
import PaymentCreditsForm from '@/components/areas/registries/customers/paymentCredits/PaymentCreditsForm.vue'
import OperatorsListSection from "@/components/homepage/sections/OperatorsListSection.vue";
import billServiceCalculator from '@/services/bills/billService.calculator.js';
import ManualBarcodeInput from "@/components/common/ManualBarcodeInput.vue";
import CustomerPromosForm from "@/components/areas/registries/customers/promos/CustomerPromosForm.vue"
import CustomerHistoryDashboardForm from "@/components/areas/registries/customers/form/CustomerHistoryDashboardForm.vue"
import DiscountForm from "@/components/areas/registries/services/form/DiscountForm.vue"
import operatorService from '@/services/operators/operators.service.js'
import customersService from '@/services/customers/customers.service.js';
import socketService from '@/services/socket/socket.service.js'
import imageLoader from '@/services/images.js'
import { cloneDeep } from 'lodash'
import Vue from 'vue'

export default {
  name: "CustomerSummarySection",
  components: {
    TypeDataTable,
    OperatorChip,
    StandardDialog,
    OperatorAvatar,
    OperatorsListSection,
    CustomerCardForm,
    CustomerPromosForm,
    CustomerHistoryDashboardForm,
    PaymentCreditsForm,
    ManualBarcodeInput,
    DiscountForm
  },
  data: function () {
    return {
      selectedService: undefined,
      headers: [
        {
          text: "Nome",
          value: "name",
          type: "custom"
        },
        {
          text: "Compl.",
          value: "isCompleted",
          type: "custom",
        },
        {
          text: "Operatori",
          value: "operators",
          type: "custom"
        },
        {
          text: "Qnt.",
          value: "quantity",
        },
        {
          text: "Prezzo (cad.)",
          value: "price",
          type: "custom",
        },
        {
          text: "Sconto %",
          value: "discount-perc",
          type: "custom",
        },
        {
          text: "Sconto (cad.)",
          value: "discount",
          type: "custom",
        },
        {
          text: "Tot.",
          value: "totalPrice",
          type: "custom",
        },
      ],
      localProvidedServices: [],
      localProvidedItems: [],
      localProvidedEntities: [],
      promoCustomers: [],
      dataTableHeight: undefined,
      localCustomer: this.customer,
      serviceDetailDialog: false,
      billServiceCalculatorUpdateHandler: undefined,
      serviceDetail: undefined,
      itemDetail: undefined,
      customerCardDetailDialog: false,
      paymentCreditDetailDialog: false,
      promoDialog: false,
      scanItemDialog: false,
      historyDashboardDialog: false,
      customerCardScanning: false,
      ghostServiceBottomSheet: false,
      ghostItemBottomSheet: false,
      ghostServiceQuantity: undefined,
      ghostItemQuantity: undefined,
      ghostingBillService: undefined,
      ghostingBillItem: undefined,
      isGhostedForDiscount: false,
      useCustomerCard: false,
      customerCard: !!this.customer.activeCustomerCards && this.customer.activeCustomerCards.length > 0 ? this.customer.activeCustomerCards[0] : { customerId: this.customer.id },
      insertedItemBarcode: undefined,
      showErrorQuantityAddItem: false,
      ghostServiceQuantityFromObject: undefined,
      ghostItemQuantityFromObject: undefined,
      qtyOverflow: 0,
      discountServiceDialog: false,
      editServicePriceDialog: false,
      editItemPriceDialog: false,
      discountedPrice: undefined,
      discountService: undefined,
      servicePriceEdited: undefined,
      discountItem: undefined,
      itemPriceEdited: undefined,
      discountFormValid: false,
      remainingPromo: undefined,
      showDiscountBarcode: false,
      allowDiscountBarcode: undefined,
      showAlertDiscountPermission: false,
      itemsNotFoundDialog: false,
      countPaymentCredits: undefined,
      itemDetailDialog: false,
      discountItemDialog: false,
      customerCardEntities: undefined,
      avatarDialog: false,
      cameraLoading: false,
      isPhotoTaken: false,
      loadingPhotoUpload: false,
      avatarExists: false,
      avatarUrl: undefined,
      errorCamera: false,
      serviceDetailQuantity: 1,
      itemDetailQuantity: 1,
      loading: false,
      taxForSaloon: undefined,
      notEnoughQuantitySnackbar: false,
      notEnoughQuantitySnackbarFromDialog: false,
      serviceDetailBodyHeight: undefined,
      itemDetailBodyHeight: undefined,
      isHistoryDashboardB: false,
      historyDashboardDialogBarcode: false,
      operatorBarcode: undefined,
    }
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue()
      }
    },
    customer: {
      type: Object,
      default: function () {
        return {}
      },
    },
    disabled: {
      type: Boolean,
      default: false
    },
    bill: {
      type: Object,
      default: () => ({})
    },
    providedServices: {},
    providedItems: {},
    scanning: {
      type: Boolean,
      default: false
    },
    cardDetailDialog: {
      type: Boolean,
      default: false
    },
    selectedServices: {
      type: Array,
      default: []
    },
  },
  mounted: function () {
    let self = this
    this.loading = true
    this.bus.$on('add-service', (service) => this.addService(service))

    this.bus.$on('add-operator', function (operator) {
      self.addOperator(operator)
    })

    this.bus.$on('reload-card', () => {
      this.customerCard = !!this.localCustomer.activeCustomerCards && this.localCustomer.activeCustomerCards.length > 0 ? this.localCustomer.activeCustomerCards[0] : { customerId: this.localCustomer.id }
    })

    this.bus.$on('open-credits', () => this.openPaymentCreditsView())

    this.loadCustomerService().then(() => {
      this.loadCountPaymentCredits()
      this.loadCustomerItems().then(() => { })
      this.billServiceCalculatorUpdateHandler = () => {
        this.totalValue = Number(billServiceCalculator.toBePaid)
        this.discountOnTotal = Number(billServiceCalculator.discountOnTotal)
        this.cardSubtract = Number(billServiceCalculator.cardSubtract)
        this.localProvidedServices = cloneDeep(billServiceCalculator.discountedBillServices)
        this.localProvidedItems = cloneDeep(billServiceCalculator.discountedBillItems)
        this.localProvidedEntities = cloneDeep(billServiceCalculator.discountedBillEntities)
        this.customerCardEntities = billServiceCalculator.customerCardEntities
        this.remainingPromo = billServiceCalculator.remainingPromo
        this.useCustomerCard = billServiceCalculator.customerCard

      }
      this.billServiceCalculatorUpdateHandler = this.billServiceCalculatorUpdateHandler.bind(this)
      this.billServiceCalculatorUpdateHandler()
      billServiceCalculator.on('update', this.billServiceCalculatorUpdateHandler)
      this.loading = false
    })

    socketService.on('bills:addService', (data) => {
      if (!!data && !!data.bill && !!data.bill.id && data.bill.id == this.bill.id) {
        this.loadCustomerService().then(() => {
          this.reloadServiceDetail()
        })
      }
    })

    socketService.on('bills:removeServiceByServiceId', (data) => {
      if (!!data && !!data.bill && !!data.bill.id && data.bill.id == this.bill.id) {
        this.loadCustomerService().then(() => {
          this.reloadServiceDetail()
        })
      }
    })
  },
  methods: {
    openEditServicePriceDialog() {
      if (this.isGhostedForDiscount) {
        this.$delegates.snackbar('Rimuovere prima il servizio dal concordato')
      } else {
        this.editServicePriceDialog = true
        if (!!this.serviceDetail && !!this.serviceDetail.discount && this.serviceDetail.discount > 0) {
          this.showAlertDiscountPermission = true
        } else
          this.showAlertDiscountPermission = false
      }
    },
    openEditItemPriceDialog() {
      if (this.isGhostedForDiscount) {
        this.$delegates.snackbar('Rimuovere prima il servizio dal concordato')
      } else {
        this.editItemPriceDialog = true
        if (!!this.itemDetail && !!this.itemDetail.discount && this.itemDetail.discount > 0) {
          this.showAlertDiscountPermission = true
        } else
          this.showAlertDiscountPermission = false
      }
    },
    openDiscountServiceDialog() {
      if (this.isGhostedForDiscount) {
        this.$delegates.snackbar('Rimuovere prima il servizio dal concordato')
      } else {
        this.discountServiceDialog = true
        if (!!this.serviceDetail && !!this.serviceDetail.discount && this.serviceDetail.discount > 0) {
          this.showAlertDiscountPermission = true
        } else
          this.showAlertDiscountPermission = false
      }
    },
    openDiscountItemDialog() {
      if (this.isGhostedForDiscount) {
        this.$delegates.snackbar('Rimuovere prima il servizio dal concordato')
      } else {
        this.discountItemDialog = true
        if (!!this.itemDetail && !!this.itemDetail.discount && this.itemDetail.discount > 0) {
          this.showAlertDiscountPermission = true
        } else
          this.showAlertDiscountPermission = false
      }
    },
    loadCountPaymentCredits() {
      if (!!this.localCustomer && !!this.localCustomer.id) {
        customersService.getPaymentCredits(this.localCustomer, [
          { type: 'custom', operator: 'custom', field: 'status', name: 'Stato', label: 'Stato', color: "", value: ['beginning'], icon: "mdi-list-status" },  
          { type:'custom', operator:'custom', field: 'billStatus', name: 'Stato Scontrino', label: 'Stato Scontrino', color: "", value: {
            billServiceStatus: 'notPaid',
            billItemStatus: 'notPaid',
          }, icon: "mdi-list-status" }
        ]).then((results) => {
          this.countPaymentCredits = (results.rows.filter(pc => pc.status != 'finished')).length
        })
      }
    },
    resetPrice(type) {
      if (type == 'service')
        billService.updateBillServiceById({
          ...this.serviceDetail,
          'editPriceType': null,
          'editPriceQuantity': null,
          'editPriceValue': null,
        }).then(() => {
          this.loadCustomerService().then(() => {
            this.reloadServiceDetail()
            this.editServicePriceDialog = false
            this.servicePriceEdited = {}
          })
        })
      else {
        billService.updateBillItemById({
          ...this.itemDetail,
          'editPriceType': null,
          'editPriceQuantity': null,
          'editPriceValue': null,
        }).then(() => {
          this.loadCustomerItems().then(() => {
            this.reloadItemDetail()
            this.editItemPriceDialog = false
            this.itemPriceEdited = {}
          })
        })
      }
    },
    editPrice(type) {
      if (type == 'service') {
        let localValue = this.servicePriceEdited["editPriceValue"]
        if (this.servicePriceEdited["mode"] == 1)
          localValue = -localValue

        billService.updateBillServiceById({
          ...this.serviceDetail,
          'editPriceValue': localValue,
          'editPriceType': this.servicePriceEdited["editPriceType"]
        }).then(() => {
          this.loadCustomerService().then(() => {
            this.reloadServiceDetail()
            this.editServicePriceDialog = false
          })
        })
      } else if (type == 'item') {
        let localValue = this.itemPriceEdited["editPriceValue"]
        if (this.itemPriceEdited["mode"] == 1)
          localValue = -localValue
        billService.updateBillItemById({
          ...this.itemDetail,
          'editPriceValue': localValue,
          'editPriceType': this.itemPriceEdited["editPriceType"]
        }).then(() => {
          this.loadCustomerItems().then(() => {
            this.reloadItemDetail()
            this.editItemPriceDialog = false
          })
        })
      }

    },
    removeDiscount(type) {
      if (type == 'service')
        billService.updateBillServiceById({
          ...this.serviceDetail,
          'discountType': null,
          'discountQuantity': null,
          'discountValue': null,
        }).then(() => {
          this.loadCustomerService().then(() => {
            this.reloadServiceDetail()
            this.discountServiceDialog = false
            this.discountService = {}
          })
        })
      else {
        billService.updateBillItemById({
          ...this.itemDetail,
          'discountType': null,
          'discountQuantity': null,
          'discountValue': null,
        }).then(() => {
          this.loadCustomerItems().then(() => {
            this.reloadItemDetail()
            this.discountItemDialog = false
            this.discountItem = {}
          })
        })
      }
    },
    setDiscount(type) {
      if (type == 'service') {
        let localValue = this.discountService["discountValue"]
        if (this.discountService["mode"] == 1)
          localValue = -localValue

        billService.updateBillServiceById({
          ...this.serviceDetail,
          'discountValue': localValue,
          'discountType': this.discountService["discountType"]
        }).then(() => {
          this.loadCustomerService().then(() => {
            this.reloadServiceDetail()
            this.discountServiceDialog = false
          })
        })
      } else if (type == 'item') {
        let localValue = this.discountItem["discountValue"]
        if (this.discountItem["mode"] == 1)
          localValue = -localValue
        billService.updateBillItemById({
          ...this.itemDetail,
          'discountValue': localValue,
          'discountType': this.discountItem["discountType"]
        }).then(() => {
          this.loadCustomerItems().then(() => {
            this.reloadItemDetail()
            this.discountItemDialog = false
          })
        })
      }

    },
    handleConfirmDiscount() {
      operatorService.canPerformOperation(this.allowDiscountBarcode, "ApplyDiscounts").then((result) => {
        if (result) {
          this.showDiscountBarcode = false
        } else {
          this.$delegates.snackbar('Non hai i permessi per eseguire questa azione!')
        }
      }).catch(() => {
        this.$delegates.snackbar('Badge non riconosciuto')
      })
    },
    calculateTotalPriceWithDiscount(service) {
      if (!!service) {
        if (!!this.calculateNewPrice(service, service.discount)) {
          return Number(service.quantity * this.calculateNewPrice(service, service.discount)).toFixed(2)
        }
      }
    },
    calculateNewPrice(service, discount) {
      let localPrice = Number(service.service.price)

      if (!!service.editPriceType && !!service.editPriceValue) {
        if (service.editPriceType == 'number') {
          localPrice -= Number(service.editPriceValue)
        }
        else if (service.editPriceType == 'percentage') {
          localPrice -= Number((localPrice * (service.editPriceValue / 100)).toFixed(2))
        }
      }

      if (!!discount) {
        return localPrice - Number(discount) / Number(service.quantity)
      }
      return localPrice
    },
    calculateNewPriceItem(item, discount) {
      let localPrice = Number(item.item.priceSelling)
      if (!!item.editPriceType && !!item.editPriceValue) {
        if (item.editPriceType == 'number') {
          localPrice -= Number(item.editPriceValue)
        }
        else if (item.editPriceType == 'percentage') {
          localPrice -= Number((localPrice * (item.editPriceValue / 100)).toFixed(2))
        }
      }
      

      if (!!discount) {
        return localPrice - Number(discount) / Number(item.quantity)
      }
      return localPrice
    },
    calculateTotalPriceItemWithDiscount(item) {
      if (!!item) {
        if (!!this.calculateNewPriceItem(item, item.discount)) {
          return Number(item.quantity * this.calculateNewPriceItem(item, item.discount)).toFixed(2)
        }
      }
    },
    handleDiscountFormValid(valid) {
      this.discountFormValid = valid
    },
    loadCustomerService() {
      this.loading = true
      return new Promise((resolve, reject) => {
        if (this.customerSelected && !!this.bill) {
          billService.servicesList(this.bill).then((services) => {
            this.$emit('update:provided-services', services)
            this.localProvidedServices = cloneDeep(services)
            this.loading = false
            resolve(this.localProvidedServices)
          })
        } else {
          this.loading = false
          resolve([])
        }
      })
    },
    loadCustomerItems() {
      this.loading = true
      return new Promise((resolve, reject) => {
        if (this.customerSelected && !!this.bill) {
          billService.itemList(this.bill).then((items) => {
            this.$emit('update:provided-items', items)
            this.localProvidedItems = items
            this.loading = false
            resolve(items)
          })
        } else
          this.loading = false
      })
    },
    setIsCompleted(item) {
      this.loading = true
      if (item.type == "service") {
        billService.updateBillServiceById(item).then((results) => {
          this.loading = false
          this.$emit('update:provided-services', this.localProvidedServices)
        })
      } else if (item.type == "item") {
        billService.updateBillItemById(item).then((results) => {
          this.loading = false
          this.$emit('update:provided-items', this.localProvidedItems)
        })
      }
    },
    addService(service, quantity) {
      this.loading = true
      if (this.customerSelected) {
        if (Number(quantity) <= 0) {
          this.$delegates.snackbar('Impossibile eseguire con questa quantità!')
          return
        }

        if (this.taxForSaloon !== undefined && this.taxForSaloon !== null) service.tax = this.taxForSaloon

        billService.addServiceToBill(this.bill, service, quantity)
          .then(_ => {
            this.loadCustomerService().then(() => {

              if (!!this.serviceDetail && !!this.serviceDetail.billServiceOperators.length > 0) {
                let localOperators = this.serviceDetail.billServiceOperators
                let maxOp = localOperators.reduce((max, op) => Number(max.quantity) > Number(op.quantity) ? max : op)
                billService.updateOperatorFromBillService(this.serviceDetail, maxOp.operator, (Number(maxOp.quantity) + Number(quantity))).then(() => {
                  this.loadCustomerService().then(() => {
                    this.reloadServiceDetail()
                  })
                })
              } else {
                this.reloadServiceDetail()
              }
            })
          })
      } else
        this.loading = false
    },
    async addItem(item, quantity, fromDialog = false) {
      this.loading = true
      if (this.customerSelected) {
        if (Number(quantity) <= 0) {
          this.$delegates.snackbar('Impossibile eseguire con questa quantità!')
          return
        }

        if (this.taxForSaloon !== undefined && this.taxForSaloon !== null) item.tax = this.taxForSaloon

        let res = await billService.addItemToBill(this.bill, item, quantity)
        res = res.filter(r => !r.qtyOverflow)
        if(res.length == 0){
          this.loading = false
          if(!fromDialog)
            this.notEnoughQuantitySnackbar = true
          else
            this.notEnoughQuantitySnackbarFromDialog = true

          return false
        }
        this.loadCustomerItems().then(() => {
          if (!!this.itemDetail && !!this.itemDetail.billItemOperators && this.itemDetail.billItemOperators.length > 0) {
            let localOperators = this.itemDetail.billItemOperators
            let maxOp = localOperators.reduce((max, op) => Number(max.quantity) > Number(op.quantity) ? max : op)
            billService.updateOperatorFromBillItem(this.itemDetail, maxOp.operator, (Number(maxOp.quantity) + Number(quantity))).then(() => {
              this.loadCustomerItems().then(() => {
                this.reloadItemDetail()
              })
            })
          } else {
            this.reloadItemDetail()
          }
        })
      }
    },
    addOperator(operator) {
      this.loading = true
      if (this.customerSelected) {
        let object
        if (this.serviceSelected) {
          object = this.selectedService[0]
        } else {
          for (let i = 0; i < this.filteredPrividedEverything.length; i += 1) {
            if (!!this.filteredPrividedEverything[i].billServiceOperators && this.filteredPrividedEverything[i].billServiceOperators.length == 0) {
              object = this.filteredPrividedEverything[i]
              break
            }

            if (!!this.filteredPrividedEverything[i].billItemOperators && this.filteredPrividedEverything[i].billItemOperators.length == 0) {
              object = this.filteredPrividedEverything[i]
              break
            }
          }
        }

        if (!object) return

        if (!!object.service) {
          billService.setOperatorsOnBillService(object, operator)
            .then(_ => {
              this.loadCustomerService()
            })
        } else {
          billService.setOperatorsOnBillItem(object, operator)
            .then(_ => {
              this.loadCustomerItems()
            })
        }
      } else
        this.loading = false
    },
    updateOperatorOfServiceDetail(service, operator, quantity) {
      if (this.customerSelected) {
        if (quantity > service.quantity) {
          this.$delegates.snackbar('Quantità massima già raggiunta!')
          return
        }
        else if (quantity === 0) {
          this.removeOperator(service, operator)
        }
        else {
          billService.updateOperatorFromBillService(service, operator, quantity).then(() => {
            this.loadCustomerService().then(() => {
              this.reloadServiceDetail()
            })
          })
        }
      }
    },
    updateOperatorOfItemDetail(item, operator, quantity) {
      if (this.customerSelected) {
        if (quantity > item.quantity) {
          this.$delegates.snackbar('Quantità massima già raggiunta!')
          return
        }
        else if (quantity === 0) {
          this.removeOperator(item, operator)
        }
        else {
          billService.updateOperatorFromBillItem(item, operator, quantity).then(() => {
            this.loadCustomerItems().then(() => {
              this.reloadItemDetail()
            })
          })
        }
      }
    },
    addOperatorToServiceDetail(operator) {
      this.loading = true
      if (this.customerSelected && !!this.serviceDetail) {

        let localOperators = this.serviceDetail.billServiceOperators

        if (localOperators.filter(op => Number(op.operatorId) === Number(operator.id)).length > 0) {
          this.$delegates.snackbar('Operatore già assegnato')
          this.reloadServiceDetail()
          return
        }

        billService.addOperatorOnBillService(this.serviceDetail, operator).then(() => {
          this.loadCustomerService().then(() => {
            this.reloadServiceDetail()
          })
        })
      } else
        this.loading = false
    },
    addOperatorToItemDetail(operator) {
      if (this.customerSelected && !!this.itemDetail) {

        let localOperators = this.itemDetail.billItemOperators

        if (localOperators.filter(op => Number(op.operatorId) === Number(operator.id)).length > 0) {
          this.$delegates.snackbar('Operatore già assegnato')
          this.reloadItemDetail()
          return
        }

        billService.setOperatorsOnBillItem(this.itemDetail, operator).then(() => {
          this.loadCustomerItems().then(() => {
            this.reloadItemDetail()
          })
        })
      }
    },
    reloadServiceDetail() {
      if (!!this.serviceDetail) {
        for (let i = 0; i < this.localProvidedServices.length; i += 1) {
          if (parseInt(this.localProvidedServices[i].id) == parseInt(this.serviceDetail.id)) {
            this.serviceDetail = this.localProvidedServices[i]
          }
        }
        this.serviceDetailQuantity = 1
      }
      this.loading = false
    },
    reloadItemDetail() {
      if (!!this.itemDetail) {
        for (let i = 0; i < this.localProvidedItems.length; i += 1) {
          if (parseInt(this.localProvidedItems[i].id) == parseInt(this.itemDetail.id)) {
            this.itemDetail = this.localProvidedItems[i]
          }
        }
        this.itemDetailQuantity = 1
      }
      this.loading = false
    },
    removeOperator(object, operator) {
      this.loading = true
      if (this.customerSelected) {
        if (object.type == 'service') {

          let localOperators = object.billServiceOperators

          billService.removeOperatorFromBillService(object, operator).then(() => {
            if (localOperators.length > 1) {
              let maxOp = localOperators.reduce((max, op) => Number(max.quantity) > Number(op.quantity) ? max : op)
              let removedOp = localOperators.filter(op => Number(op.operatorId) === Number(operator.id))
              let otherOps = localOperators.filter(op => Number(op.operatorId) !== Number(operator.id))

              const operatorToUpdate = Number(removedOp[0].operatorId) === Number(maxOp.operatorId) ? otherOps[0].operator : maxOp.operator
              const updateQuantity = (Number(otherOps[0].quantity) + Number(removedOp[0].quantity)) > object.quantity ? object.quantity : (Number(otherOps[0].quantity) + Number(removedOp[0].quantity))

              billService.updateOperatorFromBillService(object, operatorToUpdate, updateQuantity).then(() => {
                this.loadCustomerService().then(() => {
                  this.reloadServiceDetail()
                })
              })
            }
            else {
              this.loadCustomerService().then(() => {
                this.reloadServiceDetail()
              })
            }
          })
        }

        if (object.type == 'item') {
          let localOperators = object.billItemOperators

          billService.removeOperatorFromBillItem(object, operator).then(() => {
            if (localOperators.length > 1) {
              let maxOp = localOperators.reduce((max, op) => Number(max.quantity) > Number(op.quantity) ? max : op)
              let removedOp = localOperators.filter(op => Number(op.operatorId) === Number(operator.id))
              let otherOps = localOperators.filter(op => Number(op.operatorId) !== Number(operator.id))

              const operatorToUpdate = Number(removedOp[0].operatorId) === Number(maxOp.operatorId) ? otherOps[0].operator : maxOp.operator
              const updateQuantity = (Number(otherOps[0].quantity) + Number(removedOp[0].quantity)) > object.quantity ? object.quantity : (Number(otherOps[0].quantity) + Number(removedOp[0].quantity))

              billService.updateOperatorFromBillItem(object, operatorToUpdate, updateQuantity).then(() => {
                this.loadCustomerItems().then(() => {
                  this.reloadItemDetail()
                })
              })
            }
            else {
              this.loadCustomerItems().then(() => {
                this.reloadItemDetail()
              })
            }
          })
        }
      } else
        this.loading = false
    },
    removeService(service, quantity) {
      this.loading = true
      if (this.customerSelected && !!this.bill) {
        if (Number(quantity) <= 0 || Number(quantity) > Number(this.serviceDetail.quantity)) {
          this.$delegates.snackbar('Impossibile eseguire con questa quantità!')
          return
        }

        billService.removeServiceFromBill(this.bill, service, quantity).then((services) => {
          this.loadCustomerService().then(() => {
            let localOperators = this.serviceDetail.billServiceOperators
            if (localOperators.length > 0) {
              let maxOp = localOperators.reduce((max, op) => Number(max.quantity) > Number(op.quantity) ? max : op)
              let newQuantity = (Number(maxOp.quantity) - Number(quantity))

              if (newQuantity > 0) {
                billService.updateOperatorFromBillService(this.serviceDetail, maxOp.operator, newQuantity).then(() => {
                  this.loadCustomerService().then(() => {
                    this.reloadServiceDetail()
                  })
                })
              }

              else {
                billService.removeOperatorFromBillService(this.serviceDetail, maxOp.operator).then(() => {
                  this.loadCustomerService().then(() => {
                    this.reloadServiceDetail()
                  })
                })
              }
            }
            else
              this.loadCustomerService().then(() => {
                this.reloadServiceDetail()
              })
          })
        })
      }
    },
    removeItem(item, quantity) {
      this.loading = true
      if (this.customerSelected && !!this.bill) {
        if (Number(quantity) <= 0 || Number(quantity) > Number(this.itemDetail.quantity)) {
          this.$delegates.snackbar('Impossibile eseguire con questa quantità!')
          return
        }

        billService.removeItemFromBill(this.bill, item, quantity).then((items) => {
          this.loadCustomerItems().then(() => {
            let localOperators = this.itemDetail.billItemOperators
            if (!!localOperators && localOperators.length > 0) {
              let maxOp = localOperators.reduce((max, op) => Number(max.quantity) > Number(op.quantity) ? max : op)
              let newQuantity = (Number(maxOp.quantity) - Number(quantity))

              if (newQuantity > 0) {
                billService.updateOperatorFromBillItem(this.itemDetail, maxOp.operator, newQuantity).then(() => {
                  this.loadCustomerItems().then(() => {
                    this.reloadItemDetail()
                  })
                })
              }
              else {
                billService.removeOperatorFromBillItem(this.itemDetail, maxOp.operator).then(() => {
                  this.loadCustomerItems().then(() => {
                    this.reloadItemDetail()
                  })
                })
              }
            } else {
              this.loadCustomerItems().then(() => {
                this.reloadItemDetail()
              })
            }
          })
        })
      }
    },
    deleteElement(item) {
      if (item.service)
        this.deleteService(item.service)
      else if (item.item) {
        this.deleteItem(item.item)
      }
    },
    deleteAllElements() {
      this.deleteAllServices()
      this.deleteAllItems()
    },
    deleteItem(item) {
      if (this.customerSelected && !!this.bill) {
        billService.deleteItemFromBill(this.bill, item).then(() => {
          this.loadCustomerItems()
        })
      }
    },
    deleteAllItems() {
      if (this.customerSelected && !!this.bill) {
        billService.deleteAllItems(this.bill).then(() => {
          this.loadCustomerItems()
        })
      }
    },
    deleteService(service) {
      if (this.customerSelected && !!this.bill) {
        billService.deleteServiceFromBill(this.bill, service).then((services) => {
          this.loadCustomerService()
        })
      }
    },
    deleteAllServices() {
      if (this.customerSelected && !!this.bill) {
        billService.deleteAll(this.bill).then(() => {
          this.loadCustomerService()
        })
      }
    },
    showServiceDetail(billService) {
      if (this.customerSelected && !!this.bill) {
        this.serviceDetailDialog = true
        this.serviceDetail = billService
        this.servicePriceEdited = {
          'editPriceQuantity': billService.editPriceQuantity,
          'editPriceType': billService.editPriceType,
          'editPriceValue': billService.editPriceValue,
        }
        this.discountService = {
          'discountQuantity': billService.discountQuantity,
          'discountType': billService.discountType,
          'discountValue': billService.discountValue,
        }
      }
    },
    showItemDetail(billItem) {
      if (this.customerSelected && !!this.bill) {
        this.itemDetailDialog = true
        this.itemDetail = billItem
        this.itemPriceEdited = {
          'editPriceQuantity': billItem.editPriceQuantity,
          'editPriceType': billItem.editPriceType,
          'editPriceValue': billItem.editPriceValue,
        }
        this.discountService = {
          'discountQuantity': billService.discountQuantity,
          'discountType': billService.discountType,
          'discountValue': billService.discountValue,
        }
      }
    },
    openCustomerCardView() {
      this.customerCardDetailDialog = true
    },
    openPaymentCreditsView() {
      this.paymentCreditDetailDialog = true
    },
    openPromoDialog() {
      this.promoDialog = true
    },
    openScanItemDialog() {
      this.scanItemDialog = true
    },
    openHistoryDashboardDialog() {
      this.historyDashboardDialog = true
    },
    async handleConfirmItemBarcode() {
      await this.addItem({
        barcode: this.insertedItemBarcode
      })
      .then(() => {
        this.insertedItemBarcode = undefined
        this.scanItemDialog = false
      })
      .catch((err) => {
        if(err.message == 'items Not Found')
          this.$delegates.snackbar('Articolo non trovato!')
        else
          this.$delegates.snackbar('Impossibile aggiungere l\'articolo!')

        this.insertedItemBarcode = undefined
        this.scanItemDialog = false
        this.loading = false
      })
    },
    handleLoadConfirmed(value) {
      this.customerCardDetailDialog = false
      this.$router.push({
        name: 'PayCustomerCardLoad',
        params: {
          customerCard: this.customerCard,
          customerCardId: this.customerCard.id,
          bill: this.bill,
          customer: !!this.localCustomer ? this.localCustomer : this.bill.customer,
        },
        query: {
          loadValue: value
        }
      })
    },
    handleUpdatePromoCustomers(newPromosCustomers) {
      this.$set(this.localCustomer, 'promosCustomers', newPromosCustomers)
      this.$emit('update:customer', this.localCustomer)
    },
    emitCustomerInput(newCustomerCard) {
      this.localCustomer.activeCustomerCards = [newCustomerCard]
      this.$emit('update:customer', this.localCustomer)
    },
    handleDoubleClickRow(row, { item: item }) {
      if (item.type == "service") {
        if (!item.ghost) {
          if (!!billServiceCalculator.customerCard) {
            this.$delegates.snackbar('Non è possibile eseguire questa azione!')
            return
          }
          if (Number(item.quantity) == 1) {
            this.ghostService(item, 1).then(() => {
              this.isGhostedForDiscount = true
              this.loadCustomerService()
            })
          } else {
            this.ghostService(item, item.quantity).then(() => {
              this.isGhostedForDiscount = true
              this.loadCustomerService()
            })
          }
        } else {
          billService.unghostService(item).then(() => {
            this.isGhostedForDiscount = false
            this.loadCustomerService()
          })
        }
      } else if (item.type == "item") {
        if (!item.ghost) {
          if (!!billServiceCalculator.customerCard) {
            this.$delegates.snackbar('Non è possibile eseguire questa azione!')
            return
          }
          if (Number(item.quantity) == 1) {
            this.ghostItem(item, 1).then(() => {
              this.isGhostedForDiscount = true
              this.loadCustomerItems()
            })
          } else {
            this.ghostItem(item, item.quantity).then(() => {
              this.isGhostedForDiscount = true
              this.loadCustomerItems()
            })
          }
        } else {
          billService.unghostItem(item).then(() => {
            this.isGhostedForDiscount = false
            this.loadCustomerItems()
          })
        }
      }
    },
    handleCustomerChange(newCustomer) {
      eventServiceForm.setCustomer(newCustomer)

      if (!!newCustomer) {
        this.customerPhoneNumber = newCustomer.cellphone
        this.customerPreferredColorOperatorId = Number(this.localCustomer.preferredColorOperatorId)
        this.customerPreferredHairCutOperatorId = Number(this.localCustomer.preferredHairCutOperatorId)
        this.customerPreferredHairCurlerOperatorId = Number(this.localCustomer.preferredHairCurlerOperatorId)
        this.customerNotes = newCustomer.noteInterests
      }
      else
        this.customerNotes = undefined
    },
    confirmGhost() {
      if (Number(this.ghostServiceQuantity) <= Number(this.ghostServiceQuantityFromObject)) {
        this.ghostService(this.ghostingBillService, this.ghostServiceQuantity).then(() => {
          this.isGhostedForDiscount = true
          this.ghostingBillService = undefined
          this.ghostServiceBottomSheet = false
          this.ghostServiceQuantity = undefined
          this.loadCustomerService()
        })
      } else {
        this.$delegates.snackbar('Quantità del numero dei servizi superata!')
      }

    },
    confirmGhostItem() {
      if (Number(this.ghostItemQuantity) <= Number(this.ghostItemQuantityFromObject)) {
        this.ghostItem(this.ghostingBillItem, this.ghostItemQuantity).then(() => {
          this.isGhostedForDiscount = true
          this.ghostingBillItem = undefined
          this.ghostItemBottomSheet = false
          this.ghostItemQuantity = undefined
          this.loadCustomerItems()
        })
      } else {
        this.$delegates.snackbar('Quantità del numero dei prodotti superata!')
      }

    },
    async ghostService(billServiceObject, quantity) {
      await billService.ghostService(billServiceObject, quantity)
    },
    async ghostItem(billItemObject, quantity) {
      await billService.ghostItem(billItemObject, quantity)
    },
    async toggleAvatarDialog() {
      if (this.avatarDialog) {
        this.isPhotoTaken = false
        this.avatarDialog = false
        this.avatarExists = false
      } else if (!!this.localCustomer.avatar) {
        this.avatarDialog = true;
        this.avatarExists = true
        this.avatarUrl = await imageLoader.getImageUrl(this.localCustomer.avatar)
      } else {
        this.avatarDialog = true;
        this.createCameraElement()
      }
    },
    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        video: true
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
          this.errorCamera = false
        })
        .catch(error => {
          this.isLoading = false;
          alert("May the browser didn't support or there is some errors.");
          this.errorCamera = true
        });
    },
    takePhoto() {
      if (this.avatarExists) {
        this.deleteAvatar()
        this.createCameraElement()
        this.avatarExists = false
      } else {
        this.isPhotoTaken = !this.isPhotoTaken;

        const context = this.$refs.canvas.getContext('2d');
        context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
      }
    },
    deleteAvatar(){
      customersService.deleteAvatar(this.customerId)
    },
    async uploadAvatar() {
      this.loadingPhotoUpload = true
      const canvas = document.getElementById("photoTaken")
      canvas.download = "AvatarTmp.png"

      var data = new FormData()
      let imageBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
      data.append("avatar", imageBlob, 'avatar.png')

      await customersService.uploadAvatar(this.localCustomer.id, data)
      this.loadingPhotoUpload = false
      this.localCustomer.avatar = (await customersService.reloadAvatar(this.localCustomer.id))[0].avatar
      this.toggleAvatarDialog()
    },
    closePromos() {
      this.bus.$emit('closePromos')
      this.promoDialog = false
    },
    activeBarcode() {
      operatorService.canPerformOperation(this.operatorBarcode, "Ghost").then(async (result) => {
        if (result) {
          this.isHistoryDashboardB = !this.isHistoryDashboardB
          await this.bus.$emit('changeB', this.isHistoryDashboardB)
        } else {
          this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
        }
      }).catch(() => {
        this.$delegates.snackbar('Attenzione! Codice non riconosciuto')
      })
      this.operatorBarcode = undefined
      this.historyDashboardDialogBarcode = false
    },
  },
  computed: {
    localScanning() {
      return this.customerCardScanning || this.scanItemDialog || this.editServicePriceDialog || this.editItemPriceDialog
    },
    filteredProvidedServices() {
      return this.localProvidedServices
    },
    filteredProvidedItems() {
      return this.localProvidedItems
    },
    filteredPrividedEverything() {
      if (!!this.customerCardEntities) {
        return this.localProvidedEntities
      } else {
        return [
          ...this.localProvidedServices.map(el => {
            el['type'] = 'service'
            el['idKey'] = el['id'] + '_service'
            return el
          }),
          ...this.localProvidedItems.map(el => {
            el['idKey'] = el['id'] + '_item'
            el['type'] = 'item'
            return el
          }),
        ]
      }
    },
    customerSelected() {
      this.loadCountPaymentCredits();
      return this.localCustomer && Object.keys(this.localCustomer).length !== 0 || this.localCustomer.constructor !== Object
    },
    serviceSelected() {
      return this.selectedService && this.selectedService.length != 0
    },
    serviceDetailDialogTitle() {
      return !!this.serviceDetail ? this.serviceDetail.service.name : undefined
    },
    itemDetailDialogTitle() {
      return !!this.itemDetail ? this.itemDetail.item.description : undefined
    },
    detailsOperators() {
      if (!!this.serviceDetail) {
        return this.serviceDetail.billServiceOperators.map(el => {
          return {
            id: el.id,
            name: el.operator.firstname + " " + el.operator.lastname,
            quantity: el.quantity,
            operator: el.operator
          }
        })
      } else if (!!this.itemDetail) {
        return this.itemDetail.billItemOperators.map(el => {
          return {
            id: el.id,
            name: el.operator.firstname + " " + el.operator.lastname,
            quantity: el.quantity,
            operator: el.operator
          }
        })
      } else {
        return []
      }
    },
    promosNumber() {
      return !!this.localCustomer && !!this.localCustomer.promosCustomers ? this.localCustomer.promosCustomers.length : undefined
    },
  },
  watch: {
    customer(newVal) {
      this.loading = true
      let ctrl = true
      if(newVal.id == this.localCustomer.id)
        ctrl = false

      this.localCustomer = newVal
      this.customerCard = !!this.customer.activeCustomerCards && this.customer.activeCustomerCards.length > 0 ? this.customer.activeCustomerCards[0] : { customerId: this.customer.id }
      this.loadCustomerService().then(async () => {
        this.loadCustomerItems()

        if(!!ctrl) {
          let customer = await customersService.get(this.customer.id)
          this.localCustomer.avatar = customer.avatar
        }
      })
    },
    customerCardScanning() {
      this.$emit('update:scanning', this.localScanning)
    },
    scanItemDialog() {
      this.$emit('update:scanning', this.localScanning)
    },
    editServicePriceDialog() {
      this.$emit('update:scanning', this.localScanning)
    },
    editItemPriceDialog() {
      this.$emit('update:scanning', this.localScanning)
    },
    cardDetailDialog(newVal) {
      this.customerCardDetailDialog = newVal
    },
    selectedService(newVal) {
      this.bus.$emit('selected-services', newVal)
    }
  },
  barcode: {
    async scan(barcode) {
      if (this.scanItemDialog && !this.disabled) {
        await this.addItem({
          barcode: this.insertedItemBarcode
        })
      }
      if (this.showDiscountBarcode && !this.showAlertDiscountPermission && !this.disabled) {
        this.allowDiscountBarcode = barcode
        this.handleConfirmDiscount()
      }
    },
  },
  settings: {
    taxForSaloon: {
      bind: 'taxForSaloon'
    }
  }
}
</script>

<style>

</style>