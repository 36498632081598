<template>
  <div style="overflow-y: clip">
    <div 
      :class="{
        'flex-column': $vuetify.breakpoint.height < 500,
        'flex-wrap': $vuetify.breakpoint.height > 500
      }"
      class="d-flex first-row" 
    >
      <div class="pa-2 pl-5 pt-3 list-section-container">
        <v-card style="height: 100%">
          <CustomersListSection
            v-model="localSelectedBill"
            :add-customer-dialog.sync="localAddCustomerDialog"
            :customer-from-voice="customerFromVoice"
            style="height: 100%; width: 100%;"
            :bus="homepageBus"
            :disabled="isLicenseBlocked || isOnlyWheelOfFortune"
          ></CustomersListSection>
        </v-card>
      </div>
      <div class="d-flex pa-2 pt-3 service-selector-container">
        <v-card color="white">
          <ServiceSelector
            style="max-height: 100%; height: 100%"
            mandatory
            @click="handleServiceSelection"
            :customer="!!localSelectedBill ? localSelectedBill.customer : undefined"
            :tooltip-delay="1750"
            show-frequent-services
          ></ServiceSelector>
        </v-card>
      </div>
    </div>
    <div 
      :class="{
        'flex-column': $vuetify.breakpoint.height < 500,
        'flex-wrap': $vuetify.breakpoint.height > 500
      }"
      class="d-flex second-row"
    >
      <div 
        class="pa-2 pl-5 pb-3 customer-summary-section" 
        :class="{
          'customer-summary-section-no-customer': (!!this.localSelectedBill && !this.localSelectedBill.customer) || !this.localSelectedBill,
          //'customer-summary-section': !!this.localSelectedBill && !!this.localSelectedBill.customer
        }"
      >
        <CustomersSummarySection
          :customer="!!localSelectedBill ? localSelectedBill.customer : undefined"
          :bill="localSelectedBill"
          :provided-services.sync="providedServices"
          :provided-items.sync="providedItems"
          :bus="homepageBus"
          :scanning.sync="customerSummarySectionScanning"
          :card-detail-dialog.sync="localOpenCardDetailDialog"
          :selected-services="selectedServices"
          @update:card-detail-dialog="this.$emit('update:openCardDetailDialog', $event)"
          @update:customer="handleCustomerUpdate"
          style="max-height: 47.5vh; height: 48.5vh"
          :disabled="cashSectionScanning"
        ></CustomersSummarySection>
      </div>
      <div 
        class="pa-2 pb-2 operators-list-section" 
        :class="{
          'operators-list-section-no-customer': (!!this.localSelectedBill && !this.localSelectedBill.customer) || !this.localSelectedBill,
          //'operators-list-section': !!this.localSelectedBill && !!this.localSelectedBill.customer
        }"
      >
        <OperatorsListSection
          v-on:click="handleOperatorSelection"
          style="max-height: 47.5vh"
          elevated
        ></OperatorsListSection>
      </div>
      <div 
        style="max-height: 100%" 
        class="pa-2 pb-3 cash-section" 
        :class="{
          'cash-section-no-customer': (!!this.localSelectedBill && !this.localSelectedBill.customer) || !this.localSelectedBill,
          //'cash-section': !!this.localSelectedBill && !!this.localSelectedBill.customer
        }"
      >
        <CashSection
          :selected-services="selectedServices"
          :bus="homepageBus"
          :bill="completeBill"
          :scanning.sync="cashSectionScanning"
          :disabled="customerSummarySectionScanning || isLicenseBlocked || isOnlyWheelOfFortune"
        ></CashSection>
      </div>
    </div>
  </div>
</template>

<style scoped>
  @media (max-width: 1379.98px){
    .list-section-container {
      width: 100%;
      height: 500px;
    }

    .service-selector-container {
      width: 100%;
      height: 500px;
      padding-left: 20px !important;
    }

    .operators-list-section {
      width: 30%;
    }

    .customer-summary-section {
      width: 70%;
    }

    .second-row {
      justify-content: center;
    }

    .cash-section {
      width: 100%;
    }

    .operators-list-section-no-customer {
      width: 25%;
    }

    .customer-summary-section-no-customer {
      width: 50%;
    }

    .cash-section-no-customer {
      width: 25%;
    }
  }

  @media (min-width: 1380px) {
    .list-section-container {
      width: 50%;
      height: 100%;
    }

    .service-selector-container {
      width: 50%; 
      max-height: 100%
    }

    .first-row {
      height: 50vh;
      max-height: 50%;
    }

    .second-row {
      height: 50vh;
      max-height: 50%;
    }

    .operators-list-section {
      width: 17%;
    }

    .customer-summary-section {
      width: 50%;
    }

    .cash-section {
      width: 33%;
    }
  }
</style>

<script>
import CustomersListSection from "@/components/homepage/sections/CustomersListSection";
import CustomersSummarySection from "@/components/homepage/sections/CustomersSummarySection";
import OperatorsListSection from "@/components/homepage/sections/OperatorsListSection.vue";
import OperatorPresenceBadgeDialog from "@/components/homepage/OperatorPresenceBadgeDialog.vue"
import CashSection from "@/components/homepage/sections/CashSection";
import ServiceSelector from '@/components/common/ServiceSelector.vue';
import Vue from 'vue'

import winoService from "@/services/wino.ai/wino.service.js"

export default {
  name: "Homepage",
  components: {
    OperatorPresenceBadgeDialog,
    CustomersListSection,
    CustomersSummarySection,
    OperatorsListSection,
    CashSection,
    ServiceSelector,
  },
  data: function () {
    return {
      localAddCustomerDialog: this.addCustomerDialog,
      homepageBus: new Vue(),
      localSelectedBill: this.selectedBill,
      providedServices: [],
      providedItems: [],
      cashSectionScanning: false,
      customerSummarySectionScanning: false,
      localOpenCardDetailDialog: !!this.selectedBill ? this.openCardDetailDialog : false,
      selectedServices: [],
      isLicenseBlocked: undefined,
      isOnlyWheelOfFortune: undefined
    };
  },
  props: {
    addCustomerDialog: {
      type: Boolean,
      default: false
    },
    selectedBill: { },
    openCardDetailDialog: {
      type: Boolean,
      default: false
    },
    customerFromVoice: {
      type: String,
      default: undefined
    }
  },
  mounted: function() {
    if (localStorage.getItem('bill')) {
      try {
        this.localSelectedBill = JSON.parse(localStorage.getItem('bill'))
      } catch(e) {
        localStorage.removeItem('bill')
      }
    }

    if(!!this.selectedBill && this.localOpenCardDetailDialog) {
      this.homepageBus.$emit('reload-bills')
      this.$nextTick(() => {
        this.homepageBus.$emit('reload-card')
      })
    }

    this.homepageBus.$on('update-bills', (bills) => {
      let bill = bills.find(b => !!b && !!this.localSelectedBill && b.id === this.localSelectedBill.id)
      if(!bill) {
        this.localSelectedBill = undefined
      } else {
        this.$nextTick(() => {
          this.localSelectedBill = bill
        })
      }
    })
  },
  methods: {
    handleServiceSelection(service) {
      this.homepageBus.$emit('add-service', service)
    },
    handleOperatorSelection(operator) {
      this.homepageBus.$emit('add-operator', operator)
    },
    handleCustomerUpdate(newCustomer) {
      let newLocalSelectedBill = {...this.localSelectedBill}
      newLocalSelectedBill.customer = newCustomer
      this.localSelectedBill = newLocalSelectedBill
    }
  },
  computed: {
    completeBill() {
      if(!this.localSelectedBill || Object.keys(this.localSelectedBill).length == 0) {
        return undefined
      } else {
        return {
          ...this.localSelectedBill,
          billServices: this.providedServices,
          billItems: this.providedItems
        }
      }
    }
  },
  watch: {
    localSelectedBill(newVal) {
      const parsedBill = JSON.stringify(newVal)
      localStorage.setItem('bill', parsedBill)
    }
  },
  isLicenseBlocked: {
    bind: 'isLicenseBlocked'
  },
  isOnlyWheelOfFortune: {
    bind: 'isOnlyWheelOfFortune'
  }
};
</script>