<template>
  <div class="d-flex flex-column justify-center align-center">
    <v-chip
      :color="localColor" 
      class="ma-1"
      :style="{
        'min-width': !!minWidth ? minWidth : undefined,
        'max-width': !!maxWidth ? maxWidth : undefined,
        'width': !!width ? width : undefined,
        'background-image': !!background && !color ? background : undefined,
        'color': fontColor ? fontColor : undefined,
      }"
      @click="$emit('click', $event)"
      :ripple="false"
    >
      <div class="d-flex" :style="{
        'min-width': !!minWidth ? minWidth : undefined,
        'max-width': !!maxWidth ? maxWidth : undefined,
        'width': !!width ? width : undefined
      }">
        <div
          class="animated"
          :class="{
            'font-title-expanded': expanded,
            'font-title-collapsed': !expanded
          }"
        >{{title}}</div>
        <v-expand-x-transition>
          <div
            class=""
            v-if="!expanded"
          >{{value}}</div>
        </v-expand-x-transition>
      </div>
    </v-chip>
    <v-expand-transition>
      <div class="text-caption text-center" v-if="expanded">{{description}}</div>
    </v-expand-transition>
    <v-expand-transition>
      <div v-if="expanded">
        <div 
          class="text-overline text-center my-4" 
          style="font-size: 15pt !important"
        >
          {{value}}
        </div>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
export default {
  name: "ExpandibleAmount",
  components: {},
  data: function() {
    return {
    }
  },
  props: {
    title: {
      type: String,
      default: undefined
    },
    description: {
      type: String,
      default: undefined
    },
    expanded: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: undefined
    },
    minWidth: {
      type: String
    },
    background: {
      type: String,
      default: 'linear-gradient(45deg, rgb(138, 119, 226, 0.9), rgb(80, 191, 225, 0.9))'
    },
    fontColor: {
      type: String,
      default: 'white'
    },
    width: {
      type: String
    },
    maxWidth: {
      type: String
    },
    value: { }
  },
  computed: {
    localColor() {
      return this.color
    }
  }
}
</script>

<style scoped>
.animated {
  transition: all .5s;
}

.font-title-expanded {
  font-size: 1.25rem !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  text-align: center;
  width: 100%;
  animation-name: expandWidth;
  animation-duration: .5s;
}

.font-title-collapsed {
  font-weight: 900 !important;
  animation-name: collapseWidth;
  animation-duration: .5s;
  margin-right: auto;
  /* width: fit-content */
}
</style>

<style>

.back-color-gradient {
  background-image: linear-gradient(#8a77e2, #50bfe1);
}

@keyframes expandWidth {
  from {
    width: 0%
  }

  to {
    width: 100%
  }
}

@keyframes collapseWidth {
  from {
    width: 100%;
  }

  to {
    width: 0%;
  }
}
</style>