<template>
  <div>
    <div
      class="text-center font-weight-thin"
      v-if="cashDesks.length == 0"
    >
      Nessuna cassa
    </div>
    <div v-else>
      <ResponsiveCardSelector
        :items="cashDesks"
        card-max-height="150"
        card-min-height="150"
        card-max-width="150"
        card-min-width="150"
        :mandatory="mandatory"
        :loading="localLoading || loading"
        :disabled="disabled"
        :return-object="returnObject"
        v-model="localValue"
        @input="$emit('input', localValue)"
        @click="$emit('click', $event)"
        :card-disabled="(item) => {
          if(disableClosed) {
            return item.status == 'closed'
          } else if(disableOpened) {
            return item.status == 'opened'
          } else {
            return false
          }
        }"
      >
        <template v-slot:item="{item, cardMinHeight}">
          <div
            class="pa-2 d-flex flex-column justify-center align-center"
            :style="{'min-height': cardMinHeight.indexOf('px') != -1 ? cardMinHeight : cardMinHeight + 'px'}"
          >
          <v-btn 
            icon 
            style="position: absolute; right: 0.5px; top: 0.5px"
            :disabled="item.status == 'closed'"
            color="red"
            @click="$emit('cash-desk-close', item)"
          >
            <v-icon>mdi-close-circle-outline</v-icon>
          </v-btn>
            <div class="text-center">{{item.name}}</div>
            <div class="text-caption font-weight-thin">
              <span v-if="item.enabled">
              {{$translations.t('enums.cashDesks.status.' + item.status)}}
              <br/>
                <span v-if="item.status == 'opened'">{{item.cashDeskLog[item.cashDeskLog.length-1].operator.firstname}}</span>
              </span>
              <span v-else>disabilitata</span>
            </div>
          </div>
        </template>
      </ResponsiveCardSelector>
    </div>
  </div>
</template>

<script>
import ResponsiveCardSelector from './ResponsiveCardSelector.vue'
import cashDeskService from "@/services/cashDesks/cashDesks.service.js";

export default {
  name: "CashDeskSelector",
  components: { 
    ResponsiveCardSelector,
  },
  data: function() {
    return {
      cashDesks: [ ],
      colorize: false,
      localLoading: false,
      localValue: this.value || this.multiple ? [] : undefined
    }
  },
  props: {
    value: {},
    multiple: {
      type: Boolean,
      default: false
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    disableClosed: {
      type: Boolean,
      default: false
    },
    disableOpened: {
      type: Boolean,
      default: false
    },
    bus: { }
  },
  mounted: function() {
    if(!!this.bus) {
      this.bus.$on('reload', () => {
        this.loadCashDesks()
      })
    }

    this.loadCashDesks()
  },
  methods: {
    loadCashDesks() {
      this.localLoading = true
      cashDeskService.list().then((cashDesks) => {
        this.cashDesks = cashDesks.rows
        this.localLoading = false
      })
    },
  },
  watch: {
    value(newVal) {
      this.localValue = newVal
    }
  },
}
</script>

<style>

</style>
