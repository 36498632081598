var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('v-chip',{staticClass:"ma-1",style:({
      'min-width': !!_vm.minWidth ? _vm.minWidth : undefined,
      'max-width': !!_vm.maxWidth ? _vm.maxWidth : undefined,
      'width': !!_vm.width ? _vm.width : undefined,
      'background-image': !!_vm.background && !_vm.color ? _vm.background : undefined,
      'color': _vm.fontColor ? _vm.fontColor : undefined,
    }),attrs:{"color":_vm.localColor,"ripple":false},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('div',{staticClass:"d-flex",style:({
      'min-width': !!_vm.minWidth ? _vm.minWidth : undefined,
      'max-width': !!_vm.maxWidth ? _vm.maxWidth : undefined,
      'width': !!_vm.width ? _vm.width : undefined
    })},[_c('div',{staticClass:"animated",class:{
          'font-title-expanded': _vm.expanded,
          'font-title-collapsed': !_vm.expanded
        }},[_vm._v(_vm._s(_vm.title))]),_c('v-expand-x-transition',[(!_vm.expanded)?_c('div',{},[_vm._v(_vm._s(_vm.value))]):_vm._e()])],1)]),_c('v-expand-transition',[(_vm.expanded)?_c('div',{staticClass:"text-caption text-center"},[_vm._v(_vm._s(_vm.description))]):_vm._e()]),_c('v-expand-transition',[(_vm.expanded)?_c('div',[_c('div',{staticClass:"text-overline text-center my-4",staticStyle:{"font-size":"15pt !important"}},[_vm._v(" "+_vm._s(_vm.value)+" ")])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }