<template>
  <StandardDialog 
    v-model="addCustomerDialog"
    @input="$emit('update:dialog', $event)"
    title="Aggiungi clienti"
    without-padding
    :persistent="loading"
    dialog-height="fit-content"
  >
    <template v-slot:activator="{on, attrs}">
      <v-btn
        icon
        class="ml-2"
        v-on="on"
        v-bind="attrs"
        @click="focusSearch"
        :disabled="disabled"
      >
        <v-icon>mdi-account-plus</v-icon>
      </v-btn>
    </template>
    <template v-slot:header-actions>
      <v-slide-y-transition>
        <v-btn
          icon
          class="mx-2"
          v-if="oneSelected"
          @click="editCustomer"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-slide-y-transition>
      <v-btn
        icon
        v-on:click="filterVisible = !filterVisible"
        :color="filtersActive ? 'primary' : undefined"
      >
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </template>
    
    <template v-slot:footer-actions>
      <v-btn
        text
        :disabled="loading"
        v-on:click="addCustomerDialog = false"
        color="error"
      >
        Annulla
      </v-btn>
      <v-btn
        text
        :disabled="!someSelected"
        :loading="loading"
        v-on:click="addCustomerToSaloon"
        color="deafult"
      >
        Aggiungi
      </v-btn>
    </template>
      <v-banner
        v-model="filterVisible"
        style="height: 100px"
      >
        <div class="d-flex flex-wrap align-center">
          <div class="px-3">
            <v-text-field
              v-model="filtersSearchText"
              style="width: 400px"
              filled
              hide-details="auto"
              dense
              clearable
              prepend-icon="mdi-search-web"
              placeholder="Cerca"
              :loading="loadingCustomers"
              @input="wait = true"
              v-debounce:500="filterCustomer"
              ref="focusMe"
            > 
            </v-text-field>
          </div>
          <v-switch
            class="ml-2"
            label="Clienti oggi in agenda"
            v-model="filtersTodayCustomers"
            :loading="loadingCustomers"
            @change="wait = true"
            v-debounce:500.change="filterCustomer"
          ></v-switch>
        </div>
      </v-banner>
    <TypeDataTable
      v-model="selected"
      :headers="headers"
      :items="filteredCustomers"
      :loading="loadingCustomers"
      item-key="id"
      loading-text="Caricamento clienti"
      fixed-header
      show-select
      :single-select="true"
      :show-actions="false"
      :show-select="false"
      :page.sync="page"
      :row-per-page.sync="rowPerPage"
      :total-pages.sync="totalPages"
      :height="dataTableHeight"
    >
      <template v-slot:no-data>
        Nessun cliente trovato oppure già presente in salone, <a @click="addCustomer" >aggiungilo se non esiste</a>
        <CustomerNewForm
          :open-dialog.sync="customerNewFormDialog"
          :path-to-go-back="null"
          @submit="handleFormSubmit"
          :precompiled="precompiledFormCalculator(filtersSearchText)"
        ></CustomerNewForm>
      </template>
      <template v-slot:custom-photo="{item}">
        <v-avatar
          size="40"
          tile
          v-if="item.avatar"
        >
          <v-img class="rounded-lg mb-2" id="avatar" :src="item.avatarUrl"></v-img>
        </v-avatar>
      </template>
    </TypeDataTable>
  </StandardDialog>
</template>

<script>
import StandardDialog from '@/components/common/StandardDialog'
import customerManagment from '@/services/customers/customers.service.js'
import debounce from "@/mixins/common/debounce"
import TypeDataTable from '@/components/common/TypeDataTable.vue'
import billsService from '@/services/bills/bills.service.js'
import CustomerNewForm from '@/components/areas/registries/customers/CustomersRegistryNewForm.vue'
import imageLoader from '@/services/images.js'

export default {
  name: "AddCustomerDialog",
  components: {
    StandardDialog,
    TypeDataTable,
    CustomerNewForm,
  },
  mixins: [debounce],
  data: function() {
    return {
      addCustomerDialog: this.dialog,
      filterVisible: true,
      loadingCustomers: false,
      loading: false,
      headers: [ ],
      customers: [],
      page: 1,
      rowPerPage: 20,
      totalPages: 100,
      selected: undefined,
      wait: false,
      customerNewFormDialog: false,

      // filters
      filtersSearchText: "",
      filtersTodayCustomers: false
    }
  },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    fromLuluu:{
      type: Boolean,
      default: false
    },
    saloonCtrl:{
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    externalCustomerSearch: {
      type: String,
      default: undefined
    }
  },
  mounted: async function() {
    if (!!this.externalCustomerSearch)
      this.filtersSearchText = this.externalCustomerSearch
    
    await this.loadCustomers()
    this.loadHeaders()
    

  },
  methods: {
    async loadCustomers() {
      this.loadingCustomers = true
      customerManagment.list(this.page, this.rowPerPage, this.filters).then(async(results) => {
        if (this.rowPerPage != results.rowPerPage) {
          this.rowPerPage = results.rowPerPage
        }

        if (this.page != results.page) {
          this.page = results.page
        }

        this.totalPages = results.totalPages
        if (this.totalPages < this.page) {
          this.page = this.totalPages
        }

        for(let customer of results.rows) {
          if(!!customer.avatar)
            customer.avatarUrl = await imageLoader.getImageUrl(customer.avatar)
        }
        this.customers = results.rows

        this.loadingCustomers = false
      })
    },
    loadHeaders() {
      customerManagment.fastFields().then((headers) => {
        this.headers = [
          ...headers,
          {
            text: 'Foto',
            value: 'photo',
            type: 'custom',
          }
        ]
      })
    },
    filterCustomer() {
      this.loadCustomers()
    },
    closeDialog() {
      this.addCustomerDialog = false
      this.$emit('update:dialog', false)
    },
    editCustomer() {
      this.$router.push({
        name: 'CustomersRegistryEditForm', 
        params: {
          id: this.selected[0].id
        },
        query: {
          pathToGoBack: this.$router.resolve({
            name: 'Homepage', 
            query: {
              addCustomerDialog: true
            }
          }).href
        }
      })
    },
    addCustomerToSaloon() {
      if(!!this.fromLuluu) {
        this.loading = true

        billsService.addManyCustomersToSaloon(this.selected).then(() => {
          this.closeDialog()
          this.$emit('add', this.selected)
          this.selected = []
          this.filtersSearchText = "";
          this.loading = false
        }).catch((error) => {
          console.log(error)
          this.loading = false
        })

        this.$router.push({
          name: "Homepage",
        });
      } else {
        this.loading = true
        billsService.saloonCustomersList(1, 20, {
          customers: this.selected.map(customer => ({ id: customer.id }))
        }).then((results) => {
          let confirmed = true
          if(results.rows.length != 0) {
            let confirmQuestion;
            let customersList = results.rows.map(el => el.firstname + ' ' + el.lastname).join(', ')
            if(results.rows.length == 1) {
              confirmQuestion = `Il cliente ${customersList} è già presente in salone, vuoi aggiungerlo lo stesso?`
            } else {
              confirmQuestion = `I clienti ${customersList} sono già presenti in salone, vuoi aggiungerli lo stesso?`
            }
            confirmed = confirm(confirmQuestion)
          }

          if(confirmed) {
            this.loading = true
            billsService.addManyCustomersToSaloon(this.selected).then(() => {
              this.closeDialog()
              this.$emit('add', this.selected)
              this.selected = []
              this.filtersSearchText = "";
              this.loading = false
            }).catch((error) => {
              console.log(error)
              this.loading = false
            })
          } else {
            this.loading = false
          }
        })
      }
    },
    async addCustomer() {
      if(this.saloonCtrl)
      {
        const ctrl = await customerManagment.search(this.filtersSearchText)
        if (!ctrl.length)
          this.customerNewFormDialog = true
        else
          this.$delegates.snackbar('Cliente già in salone')
      }
      else
        this.customerNewFormDialog = true
    },
    precompiledFormCalculator(str){
      let stringSplitted = str.split(' ')
      return {
        lastname: stringSplitted[0],
        firstname: stringSplitted.length > 1 ? stringSplitted[1] : ''
      }
    },
    async handleFormSubmit(customer) {
      this.customerNewFormDialog = false
      await this.loadCustomers()
      if (!!this.selected)
         this.selected.push(customer)
      else 
        this.selected = [customer]
      this.filtersSearchText = ""
    },
    focusSearch(){
      setTimeout(() => this.$refs["focusMe"].$refs.input.focus(),100)
    }
  },
  computed: {
    filteredCustomers() {
      if(this.customers.length == 0){
        this.addCustomer()
      } else {
        return this.customers
      }
    },
    oneSelected() {
      return this.selected != undefined && this.selected.length == 1
    },
    someSelected() {
      return this.selected != undefined && this.selected.length != 0
    },
    filtersActive() {
      return (this.filtersSearchText != undefined && this.filtersSearchText != null && this.filtersSearchText != '')
        || this.filtersTodayCustomers
    },
    dataTableHeight() {
      if(this.filterVisible) {
        return "calc(90vh - 300px)"
      } else {
        return "calc(90vh - 200px)"
      }
    },
    filters() {
      return {
        searchText: this.filtersSearchText,
        todayCalendarCustomers: this.filtersTodayCustomers,
        customersAlreadySelected: true
      }
    }
  },
  watch: {
    page(newVal, oldVal) {
      if(newVal != oldVal) {
        this.loadCustomers();
      }
    },
    rowPerPage(newVal, oldVal) {
      if(newVal != oldVal)
        this.loadCustomers();
    },
    addCustomerDialog(newVal) {
      if(newVal) {
        this.loadCustomers()
      }
    },
    dialog(newVal) {
      this.addCustomerDialog = newVal
    },
    filtersSearchText(newVal){
      this.filtersSearchText = newVal.trim()
      if(!newVal)
        this.filtersSearchText = ""
    }
  }
}
</script>

<style>
</style>