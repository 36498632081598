<template>
  <div style="max-height: 100%; height: 100%">
    <v-container fluid class="pa-0" v-if="!!bill">
      <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0" cols="12" ref="actionColumn">
          <div class="d-flex flex-column justify-center align-center">
            <ExpandibleAmountVue
              title="Totale"
              description="Il costo totale dei prodotti"
              :value="
                total != undefined && total != null
                  ? `${total} €`
                  : 'Non Specificato'
              "
              :max-width="maxActionWidth"
              :width="maxActionWidth"
              :expanded="expanded == 'total'"
              @click="toggleExpanded('total')"
            ></ExpandibleAmountVue>
            <ExpandibleAmountVue
              title="Totale Sconti"
              description="Il totale degli sconti applicati"
              :value="
                !!totalDiscounts ? `${totalDiscounts} €` : 'Non Specificato'
              "
              :max-width="maxActionWidth"
              :width="maxActionWidth"
              :expanded="expanded == 'totalDiscounts'"
              @click="toggleExpanded('totalDiscounts')"
            ></ExpandibleAmountVue>
            <ExpandibleAmountVue
              title="Totale Netto"
              description="Il totale meno gli sconti applicati"
              :value="!!subTotal ? `${subTotal} €` : 'Non Specificato'"
              :max-width="maxActionWidth"
              :width="maxActionWidth"
              :expanded="expanded == 'subTotal'"
              @click="toggleExpanded('subTotal')"
            ></ExpandibleAmountVue>
            <ExpandibleAmountVue
              title="Da Pagare"
              description="L'importo che il cliente deve pagare"
              :value="
                toBePaid != undefined && toBePaid != null
                  ? `${toBePaid} €`
                  : 'Non Specificato'
              "
              :max-width="maxActionWidth"
              :width="maxActionWidth"
              :expanded="expanded == 'toBePaid'"
              @click="toggleExpanded('toBePaid')"
            ></ExpandibleAmountVue>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex align-center justify-center ma-0 py-0 px-3" cols="5">
          <v-card style="width: 100%" @click="openCashDialog" :disabled="!cashDeskOpened">
            <div class="d-flex flex-column align-center justify-center pa-2">
              <div>
                <v-icon size="60">mdi-cash-multiple</v-icon>
              </div>
              <div class="text-button">
                Pagamento
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col>
          <v-alert
            type="warning"
            dense
            v-if="!cashDeskOpened"
            class="mt-2"
            @click="openCashDeskDialog"
          >
            <span style="font-size: 10pt !important">
              Attenzione, cassa ancora chiusa
            </span>
          </v-alert>
          <v-btn
            class="back-color-gradient"
            color="primary"
            @click="openCashDeskDialog"
            v-if="!cashDeskOpened"
            style="width: 100%"
          >
            Apri la cassa
            <v-icon right dark> mdi-cash-register </v-icon>
          </v-btn>
          <div v-if="cashDeskOpened" class="text-center my-2">
            <span class="font-weight-bold">Cassa aperta:</span>
            <span class="font-weight-light">{{ openedCashDesk.name }}</span>
            <br />
            <span class="font-weight-bold">Aperta alle:</span>
            <DateTimeFormattor
              class="font-weight-light"
              with-time
              :with-date="false"
              :value="openedCashDesk.cashDeskLog.openedAt"
            ></DateTimeFormattor>
            <br />
            <span class="font-weight-bold">Aperta da:</span>
            <span class="font-weight-light"
              >{{ openedCashDesk.operator.firstname }}
              {{ openedCashDesk.operator.lastname }}</span
            >
            <v-btn
              color="primary"
              @click="closeCashDesk"
              v-if="!!cashDeskOpened"
              style="width: 100%"
              class="mt-2"
            >
              Chiudi la cassa
              <v-icon right dark> mdi-cash-register </v-icon>
            </v-btn>
          </div>
        </v-col>
<!--         <v-btn
          class="back-color-gradient"
          color="primary"
          @click="openCashDeskDialog"
          v-if="!cashDeskOpened"
          style="width: 100%"
        >
          Apri la cassa
          <v-icon right dark> mdi-cash-register </v-icon>
        </v-btn> -->
<!--         <v-btn
          color="primary"
          @click="closeCashDesk"
          v-else
          style="width: 100%"
          class="mt-5"
        >
          Chiudi la cassa
          <v-icon right dark> mdi-cash-register </v-icon>
        </v-btn> -->
      </v-row>
    </v-container>
    <div
      v-else
      style="height: 100%"
      class="d-flex flex-column justify-center align-center text-body-2"
    >
      <div v-if="cashDeskOpened" class="text-center my-2">
        <span class="font-weight-bold">Cassa aperta:</span>
        <span class="font-weight-light">{{ openedCashDesk.name }}</span>
        <br />
        <span class="font-weight-bold">Aperta:</span>
        <DateTimeFormattor
          class="font-weight-light"
          with-time
          :with-date="false"
          :value="openedCashDesk.cashDeskLog.openedAt"
        ></DateTimeFormattor>
        <br />
        <span class="font-weight-bold">Aperta da:</span>
        <span class="font-weight-light"
          >{{ openedCashDesk.operator.firstname }}
          {{ openedCashDesk.operator.lastname }}</span
        >
      </div>
      <v-btn color="primary" @click="openCashDeskDialog" v-if="!cashDeskOpened">
        Apri la cassa
        <v-icon right dark> mdi-cash-register </v-icon>
      </v-btn>
      <v-btn color="primary" @click="closeCashDesk" v-else>
        Chiudi la cassa
        <v-icon right dark> mdi-cash-register </v-icon>
      </v-btn>
    </div>
    <StandardDialog
      v-model="cashDeskDialog"
      title="Apertura Cassa"
      :dialog-height="null"
      dialog-max-width="800px"
    >
      <v-row>
        <v-col class="d-flex flex-column justify-center align-center">
          <OperatorAvatar
            v-if="!!operatorFromBadge"
            :value="operatorFromBadge"
            :size="100"
            class="my-2"
          ></OperatorAvatar>
          <ManualBarcodeInput
            v-else
            :opened.sync="manualBarcodeInsert"
            v-model="insertedBarcode"
            @confirm="handleConfirmBarcode"
          ></ManualBarcodeInput>
          <div
            class="text-center"
            style="font-size: 1.2em"
            v-if="!!operatorFromBadge"
          >
            <span class="font-weight-bold"
              >{{ operatorFromBadge.firstname }}
              {{ operatorFromBadge.lastname }}</span
            >
            <br />
            <span class="font-weight-thin">scegli la cassa da aprire</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center align-center">
          <CashDeskSelector
            v-model="selectedCashDesk"
            mandatory
            @input="handleCashDeskSelectorInput"
            return-object
            disable-opened
            @cash-desk-close="handleCashDeskClose"
            :bus="bus"
          ></CashDeskSelector>
        </v-col>
      </v-row>
    </StandardDialog>
    <StandardDialog 
      v-model="forceClosureCashDeskDialog" 
      title="Chiusura Cassa" 
      :dialog-height="null"
      dialog-max-width="500px"
    >
      <ManualBarcodeInput v-model="forceCloseCashDeskBarcode" @confirm="forceCloseCashDesk">
      </ManualBarcodeInput>
    </StandardDialog>
  </div>
</template>

<script>
import cashDeskService from "@/services/cashDesks/cashDesks.service.js";
import operatorService from "@/services/operators/operators.service.js";
import ExpandibleAmountVue from "@/components/homepage/sections/ExpandibleAmount.vue";
import StandardDialog from "@/components/common/StandardDialog.vue";
import CashDeskSelector from "@/components/common/CashDeskSelector.vue";
import OperatorAvatar from "@/components/common/OperatorAvatar.vue";
import BarcodeDrawer from "@/components/common/BarcodeDrawer.vue";
import ManualBarcodeInput from "@/components/common/ManualBarcodeInput.vue";
import billBarCalculator from "@/services/bills/billBar.calculator.js";
import DateTimeFormattor from "@/components/common/DateTimeFormattor.vue";
import billServiceCalculator from "@/services/bills/billService.calculator";
import Vue from 'vue'

export default {
  name: "CashSectionBar",
  components: {
    ExpandibleAmountVue,
    StandardDialog,
    CashDeskSelector,
    OperatorAvatar,
    BarcodeDrawer,
    DateTimeFormattor,
    ManualBarcodeInput,
  },
  data: function () {
    return {
      expanded: undefined,
      maxActionWidth: undefined,
      resizeHandler: undefined,
      cashDeskOpening: false,
      openedCashDesk: undefined,
      cashDeskDialog: false,
      manualBarcodeInsert: false,
      operatorFromBadge: undefined,
      selectedCashDesk: undefined,
      insertedBarcode: undefined,

      customerCardRemaining: undefined,
      total: undefined,
      totalDiscounts: undefined,
      subTotal: undefined,
      toBePaid: undefined,
      newCustomerCardRemaining: undefined,
      forceClosureCashDeskDialog: false,
      forcedCloseCashDask: undefined,
      forceCloseCashDeskBarcode: undefined,
    };
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue()
      }
    },
    bill: {},
    scanning: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: Number,
      default: undefined,
    },
    selectedTable: {
      type: Object,
      default: () => {},
    },
    partialPayment: {
      type: Boolean,
      default: false,
    },
    goBack: {
      default: undefined
    },
    returnBill:{
      default: undefined
    }
  },
  mounted: function () {
    this.$emit("update:scanning", this.cashDeskDialog);
    this.getOpenedCashDesk();

    if (this.$refs.actionColumn) {
      this.calculateWidth();
    }

    this.resizeHandler = () => {
      this.calculateWidth();
    };
    this.resizeHandler = this.resizeHandler.bind(this);
    window.addEventListener("resize", this.resizeHandler);

    this.initializeBillCalculator();

    this.billBarCalculatorUpdateHandler = () => {
      this.total = billBarCalculator.total;
      this.totalDiscounts = billBarCalculator.discount;
      this.subTotal = billBarCalculator.subTotal;
      this.toBePaid = billBarCalculator.toBePaid;
    };
    this.billBarCalculatorUpdateHandler = this.billBarCalculatorUpdateHandler.bind(this);
    this.billBarCalculatorUpdateHandler();
    billBarCalculator.on("update", this.billBarCalculatorUpdateHandler);

  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.resizeHandler);
    billBarCalculator.off(this.billBarCalculatorUpdateHandler);
  },
  methods: {
    initializeBillCalculator() {
      if (!!this.bill) {
        billBarCalculator.setBill(this.bill);
      }
    },
    getOpenedCashDesk() {
      cashDeskService.getOpened().then((openedCashDesk) => {
        this.openedCashDesk = openedCashDesk;
      });
    },
    calculateWidth() {
      if (!!this.$refs.actionColumn) {
        this.maxActionWidth = this.$refs.actionColumn.offsetWidth - 10 + "px";
      }
    },
    toggleExpanded(name) {
      if (this.expanded == name) {
        this.expanded = undefined;
      } else {
        this.expanded = name;
      }
    },
    openCashDialog() {
      if ((!!this.bill.billServices && this.bill.billServices.length == 0 ) && (!!this.bill.billItems && this.bill.billItems.length == 0 )) {
        this.$delegates.snackbar("Inserire almeno un servizio o un prodotto per proseguire");
        return
      }

      if ((!!this.bill.customers && this.bill.customers.length > 0) && !!this.partialPayment) {
        this.$delegates.snackbar("Inserire tutti i servizi/prodotti per proseguire");
        return
      }

      let hasSomeGhostServices = this.bill.billServices && this.bill.billServices.some((el) => el.ghost)
      let hasSomeGhostItems = this.bill.billItems && this.bill.billItems.some((el) => el.ghost)
      let hasAllGhostServices = !this.bill.billServices || this.bill.billServices.every((el) => el.ghost)
      let hasAllGhostItems = !this.bill.billItems || this.bill.billItems.every((el) => el.ghost)
      if((hasSomeGhostServices || hasSomeGhostItems) && !(hasAllGhostServices && hasAllGhostItems)) {
        this.$delegates.snackbar("Tutti i servizi devono essere in concordato");
        return
      }
      
      this.$router.push({
        name: 'PayBillBar',
        params: {
          billId: this.bill.id,
          bill: this.bill,
          goBack: this.goBack ? this.goBack : 'Bar',
          tableId: !!this.selectedTable ? Number(this.selectedTable.id) : undefined,
          partialPayment: this.partialPayment,
          returnBill: this.returnBill,
          cashDeskLog: this.openedCashDesk.cashDeskLog.id,
          discountBar: billBarCalculator.discountBar,
          customerId: this.customerId,
        },
      })
    },
    openCashDeskDialog() {
      this.cashDeskDialog = true;
      this.operatorFromBadge = undefined;
      this.selectedCashDesk = undefined;
      this.insertedBarcode = undefined;
      this.$emit("update:scanning", this.cashDeskDialog);
    },
    handleConfirmBarcode() {
      operatorService.getByBarcode(this.insertedBarcode).then((results) => {
        this.operatorFromBadge = results;
        this.manualBarcodeInsert = false;
        this.tryOpenCashDesk();
      });
    },
    handleCashDeskSelectorInput(cashDesk) {
      if (!!cashDesk) {
        this.tryOpenCashDesk();
      }
    },
    tryOpenCashDesk() {
      if (!!this.operatorFromBadge && !!this.selectedCashDesk) {
        let confirmed = confirm(
          `${this.operatorFromBadge.firstname} ${this.operatorFromBadge.lastname}, vuoi davvero aprire la cassa ${this.selectedCashDesk.name}?`
        );

        if (confirmed) {
          this.cashDeskOpening = true;
          cashDeskService
            .open(this.selectedCashDesk, this.operatorFromBadge)
            .then(() => {
              this.getOpenedCashDesk();
              this.cashDeskOpening = false;
              this.cashDeskDialog = false;
            });
        }
      }
    },
    closeCashDesk() {
      if (this.cashDeskOpened) {
        cashDeskService
          .close(this.openedCashDesk, this.openedCashDesk.operator)
          .then(() => {
            this.getOpenedCashDesk();
          });
      }
    },
    handleCashDeskClose(item) {
      this.forcedCloseCashDask = item
      this.forceClosureCashDeskDialog = true
    },
    forceCloseCashDesk() {
      operatorService.canPerformOperation(this.forceCloseCashDeskBarcode, "CloseCashDesk", undefined, true)
        .then((result) => {
          if (result.result) {
            cashDeskService.close(this.forcedCloseCashDask, result.operator, true).then(() => {
              this.getOpenedCashDesk()
              this.forceClosureCashDeskDialog = false
              this.forceCloseCashDeskBarcode = undefined
              this.bus.$emit('reload')
            })
          }
          else {
            this.$delegates.snackbar("Non si hanno i permessi adeguati");
          }
        })
    },
  },
  computed: {
    billPresent() {
      return !!this.bill;
    },
    providedServicePresent() {
      return (
        this.billPresent &&
        !!this.bill.billServices &&
        Array.isArray(this.bill.billServices)
      );
    },
    cashDeskOpened() {
      return !!this.openedCashDesk;
    },
  },
  watch: {
    bill(newVal) {
      this.initializeBillCalculator();
      this.$nextTick(() => {
        this.calculateWidth();
      });
    },
    cashDeskDialog(newVal) {
      this.$emit("update:scanning", newVal);
    },
  },
};
</script>

<style>
.back-color-gradient {
  background-image: linear-gradient(#8a77e2, #50bfe1);
}
</style>