import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class menuManagement {
  list(filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/bar/menus/list",{filters: filters}).then((response) => {
        if (response.success) 
          resolve(response.results.rows);
        else 
          reject("Error during the call: list()" + response.results.error)
      });
    });
  }

  listForHierarchy(filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/bar/menus/listForHierarchy").then((response) => {
        if (response.success) 
          resolve(response.results);
        else 
          reject("Error during the call: list()" + response.results.error)
      });
    });
  }

  get(menuId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + "/bar/menus/get", {id: menuId}).then((response) => {
        if (response.success)
          resolve(response.results);
        else
          reject("Error during the call: get(menuId)" + response.results.error);
      });
    });
  }

  update(entities) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/bar/menus/update", entities).then((response) => {
        if (response.success)
          resolve(response.results);
        else
          reject("Error during the call: update(params)" + response.results.error);
      });
    });
  }

  create(menu) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/bar/menus/create", menu).then((response) => {
        if (response.success)
          resolve(response.results);
        else
          reject("Error during the call: create(menu)" + response.results.error);
      });
    });
  }

  addItemToMenu(menuId, itemId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/bar/menus/" + menuId + "/items/add", {
        menuId: menuId,
        itemId: itemId,
      }).then((response) => {
        if (response.success)
          resolve(response.results);
        else
          reject("Error during the call: addItemToMenu(menuId, itemId)" + response.results.error);
      });
    });
  }

  addEntitiesToMenu(menuId, entities) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/bar/menus/" + menuId + "/entities/add", {
        menuId: menuId,
        entities: entities,
      }).then((response) => {
        if (response.success)
          resolve(response.results);
        else
          reject("Error during the call: addEntitiesToMenu" + response.results.error);
      });
    });
  }

  menuEntityList(menuId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/bar/menus/" + menuId + "/entities/list").then((response) => {
        if (response.success) 
          resolve(response.results)
        else 
          reject("Error during the call: list()" + response.results.error)
      })
    })
  }

  delete(menu) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!menu.id) {
        reject("Menu id undefined");
        return;
      }
      
      axios.get(apiUrl + "/bar/menus/delete", {
        id: menu.id,
      }).then((response) => {
        if (!response.success)
          reject(response.results);
        else
          resolve(response);
      });
    });
  }

}

export default new menuManagement();