import axios from "axios";
import CookieService from "@/services/cookies.service";
import EventsHandler from "@/services/common/eventsHandler"

class WinoAxios extends EventsHandler {
  constructor() {
    super()
  }

  post(url, params) {
    return new Promise((resolve, reject) => {
      this._buildHeaders().then((headers) => {
        axios
          .post(url, params, { headers: headers})
          .then((response) => {
            resolve(response.data, response);
          })
          .catch((error) => {
            if (!error.response) {
              this._executeAsyncCallbacksOf('no-response').then(() => {
              }).finally(() => {
                reject(new Error('no-response'));
              })
            } else {
              console.log("generic error")
            }
          })
      })
    })
  }

  async _buildHeaders() {
    return {
      "Content-Type": "application/json",
    }
  }
}

export default new WinoAxios()