<template>
  <div style="max-height: 100%; height: 100%; overflow-y: auto; display: flex; flex-wrap: wrap; margin-right: -10px">
    <v-container fluid class="pa-0" v-if="!!bill">
      <v-row class="ma-0 pa-0" style="height:100%">
        <v-col class="ma-0 pa-0 d-flex flex-column" cols="6" style="overflow: auto;height:100%" ref="actionColumn">
          <div class="d-flex flex-column justify-top align-center mt-2" style="height:100%">
            <ExpandibleAmount title="Credito Tessera" description="Il credito residuo sulla tessera"
              :value="customerCardRemaining != undefined && customerCardRemaining != null ? `${customerCardRemaining.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}` : 'Non Specificato'"
              :max-width="maxActionWidth" :width="maxActionWidth" :expanded="expanded == 'customerCard'"
              :color="!!customerCardCreditGhost ? 'error' : undefined" @click="toggleExpanded('customerCard')">
            </ExpandibleAmount>
            <ExpandibleAmount title="Totale"
              :description="usePromos && !totalDiscounts ? `Sconto: ${Math.trunc((100*totalDiscounts)/total)}% Il costo totale dei servizi effettuati ` : 'Il costo totale dei servizi effettuati' "
              :value="total != undefined && total != null ? `${total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}` : 'Non Specificato'"
              :max-width="maxActionWidth" :width="maxActionWidth" :expanded="expanded == 'total'"
              @click="toggleExpanded('total')"></ExpandibleAmount>
            <ExpandibleAmount title="Totale Sconti" description="Il totale degli sconti applicati"
              :value="!!totalDiscounts ? `${totalDiscounts.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}` : 'Non Specificato'" :max-width="maxActionWidth"
              :width="maxActionWidth" :expanded="expanded == 'totalDiscounts'"
              @click="toggleExpanded('totalDiscounts')"></ExpandibleAmount>
            <ExpandibleAmount title="Totale Netto" description="Il totale meno gli sconti applicati"
              :value="subTotal != undefined && subTotal != null ? `${subTotal.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}` : 'Non Specificato'"
              :max-width="maxActionWidth" :width="maxActionWidth" :expanded="expanded == 'subTotal'"
              @click="toggleExpanded('subTotal')"></ExpandibleAmount>
            <ExpandibleAmount title="Da Pagare" description="L'importo che il cliente deve pagare"
              :value="toBePaid !== undefined && toBePaid !== null ? `${toBePaid.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}` : 'Non Specificato'"
              :max-width="maxActionWidth" :width="maxActionWidth" :expanded="expanded == 'toBePaid'"
              @click="toggleExpanded('toBePaid')"></ExpandibleAmount>
            <ExpandibleAmount title="Nuovo Credito" description="Credito sulla tessera dopo il pagamento"
              :value="newCustomerCardRemaining != undefined && newCustomerCardRemaining != null ? `${newCustomerCardRemaining.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}` : 'Non Specificato'"
              :max-width="maxActionWidth" :width="maxActionWidth" :expanded="expanded == 'newCustomerCard'"
              @click="toggleExpanded('newCustomerCard')"></ExpandibleAmount>
          </div>
          <div class="d-flex justify-center mr-6">
            <v-switch v-model="useCustomerCard" :disabled="!activeCard || usePromos || bill.billServices.filter(el => el.ghost).length > 0 
            || bill.billItems.filter(el => el.ghost).length > 0" hide-details="auto" @change="handleChangeCard">
              <template v-slot:label>
                <span style="font-size: 1rem">Utilizza Tessera</span>
              </template>
            </v-switch>
          </div>
          <div class="d-flex justify-center mb-5">
            <v-switch v-model="usePromos" :disabled="useCustomerCard" @change="handleChangePromo">
              <template v-slot:label>
                <span style="font-size: 1rem">Utilizza Promozioni</span>
              </template>
            </v-switch>
          </div>
        </v-col>
        <v-col class="px-3 pt-2 mb-2 d-flex flex-column" cols="6">
          <v-card @click="openCashDialog()" :disabled="!cashDeskOpened"
            style="height: 14vh; margin-bottom: 10px"
            class="d-flex flex-column align-center justify-center"
          >
            <v-hover v-slot="{ hover }">
              <div class="d-flex flex-column align-center justify-center pa-2">
                <div>
                  <v-icon size="60">mdi-cash-multiple</v-icon>
                </div>
                <div class="text-button d-flex align-center">
                  <span style="">Pagamento</span>
                  <v-expand-x-transition>
                    <v-icon v-if="hover">mdi-arrow-right</v-icon>
                  </v-expand-x-transition>
                </div>
              </div>
            </v-hover>
          </v-card>
          <div style="margin-bottom: 10px;">
            <v-alert type="warning" dense v-if="!cashDeskOpened" class="mt-auto mb-auto" @click="openCashDeskDialog"
              style="width: 100%; display: flex; flex-wrap: wrap; align-content: center; justify-content: center; height:10vh;">
              <span>
                Attenzione cassa chiusa
              </span>
            </v-alert>
          </div>
          <div v-if="cashDeskOpened" class="text-center my-2 mb-auto mt-auto">
            <span class="font-weight-bold">Cassa aperta:</span> <span
              class="font-weight-light">{{openedCashDesk.name}}</span>
            <br>
            <span class="font-weight-bold">Aperta alle:</span>
            <DateTimeFormattor class="font-weight-light" with-time :with-date="false"
              :value="openedCashDesk.cashDeskLog.openedAt"></DateTimeFormattor>
            <br>
            <span class="font-weight-bold">Aperta da:</span> <span
              class="font-weight-light">{{openedCashDesk.operator.firstname}}</span>
          </div>
          <v-btn @click="openCashDeskDialog" v-if="!cashDeskOpened" :disabled="disabled" style="width: 100%; display: flex; flex-wrap: wrap;"
            color="primary" class="back-color-gradient mt-auto">
            <span style="margin-left: 0.25vw"> Apri la cassa </span>
            <v-icon right dark style="margin-left: 0.22vw; margin-right: 0.1vw">
              mdi-cash-register
            </v-icon>
          </v-btn>
          <v-btn color="white" dark @click="closureCashDeskDialog = true" v-else style="width: 100%;background-image: linear-gradient(45deg, rgb(138, 119, 226, 0.9), rgb(80, 191, 225, 0.9));">
            <span style="margin-left: 0.25vw"> Chiudi la cassa </span>
            <v-icon right dark style="margin-left: 0.22vw; margin-right: 0.1vw">
              mdi-cash-register
            </v-icon>
          </v-btn>
          <v-btn class="mt-3" @click="billSummary" :disabled="!cashDeskOpened" color="white" style="width: 100%">
            <span style=""> Pre conto </span>
            <v-icon right>
              mdi-receipt
            </v-icon>
          </v-btn>
          <v-btn v-if="!!viewBar.active" :disabled="!cashDeskOpened" color="white" dark style="width: 100%;background-image: linear-gradient(45deg, rgb(138, 119, 226, 0.9), rgb(80, 191, 225, 0.9));" class="mt-3"
            @click="barCustomer">
            <span style=""> Bar {{!!barBillTotal ? '('+barBillTotal.toFixed(2)+')' : ''}}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div v-else style="height: 100% width: 100%" class="d-flex flex-column justify-center align-center text-body-2">
      <div v-if="cashDeskOpened" class="text-center my-2">
        <span class="font-weight-bold">Cassa aperta:</span> <span
          class="font-weight-light">{{openedCashDesk.name}}</span>
        <br>
        <span class="font-weight-bold">Aperta:</span>
        <DateTimeFormattor class="font-weight-light" with-time :with-date="false"
          :value="openedCashDesk.cashDeskLog.openedAt"></DateTimeFormattor>
        <br>
        <span class="font-weight-bold">Aperta da:</span> <span
          class="font-weight-light">{{openedCashDesk.operator.firstname}}</span>
      </div>
      <v-btn 
        color="primary" 
        @click="openCashDeskDialog" 
        v-if="!cashDeskOpened" 
        class="back-color-gradient"
        style="width: 13vw; min-width: 170px; height: 99%;"
        x-large
        :disabled="disabled"
      >
        <v-col>
          <v-row class="">
            <v-col>
              <v-icon 
                x-large
                dark
              >
                mdi-cash-register
              </v-icon>
            </v-col>
          </v-row>
          <br/>
          <v-row>
            <v-col>
              <span> Apri la cassa </span>
            </v-col>
          </v-row>
        </v-col>
      </v-btn>
      <v-btn color="white" dark @click="closureCashDeskDialog = true" v-else style="background-image: linear-gradient(rgb(138, 119, 226, 0.9), rgb(80, 191, 225, 0.9));">
        <span style="margin-left: 0.25vw"> Chiudi la cassa </span>
        <v-icon right dark>
          mdi-cash-register
        </v-icon>
      </v-btn>
    </div>
    <StandardDialog v-model="cashDeskDialog" title="Apertura Cassa" :dialog-height="null" dialog-max-width="800px">
      <v-row>
        <v-col class="d-flex flex-column justify-center align-center">
          <OperatorAvatar v-if="!!operatorFromBadge" :value="operatorFromBadge" :size="100" class="my-2">
          </OperatorAvatar>
          <ManualBarcodeInput v-else :opened.sync="manualBarcodeInsert" v-model="insertedBarcode"
            @confirm="handleConfirmBarcode"></ManualBarcodeInput>
          <div class="text-center" style="font-size: 1.2em" v-if="!!operatorFromBadge">
            <span class="font-weight-bold">{{operatorFromBadge.firstname}} {{operatorFromBadge.lastname}}</span>
            <br>
            <span class="font-weight-thin">scegli la cassa da aprire</span>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-center align-center">
          <CashDeskSelector v-model="selectedCashDesk" mandatory @input="handleCashDeskSelectorInput" return-object
            disable-opened @cash-desk-close="handleCashDeskClose" :bus="bus"></CashDeskSelector>
        </v-col>
      </v-row>
    </StandardDialog>
    <StandardDialog v-model="closureCashDeskDialog" title="Chiusura Cassa" :dialog-height="null" dialog-max-width="500px">
      <ManualBarcodeInput v-model="closeCashDeskBarcode" @confirm="closeCashDesk">
      </ManualBarcodeInput>
    </StandardDialog>
    <StandardDialog v-model="forceClosureCashDeskDialog" title="Chiusura Cassa" :dialog-height="null"
      dialog-max-width="500px">
      <ManualBarcodeInput v-model="forceCloseCashDeskBarcode" @confirm="forceCloseCashDesk">
      </ManualBarcodeInput>
    </StandardDialog>

    <StandardDialog v-model="discountPermissionDialog" title="Promozione o Tessera" dialog-height="calc(60vh - 1)"
      dialog-width="40vw" persistent>
      <div v-if="showAlertDiscountPermission" class="mt-3 d-flex justify-center align-center">
        <span class="font-weight-normal">C'è già uno sconto applicato a questo/i servizio/i. Continuare?</span>
      </div>
      <ManualBarcodeInput class="mt-3" v-if="!showAlertDiscountPermission" v-model="allowDiscountBarcode"
        @confirm="handleConfirmDiscount">
      </ManualBarcodeInput>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn text color="default" v-if="showAlertDiscountPermission" @click="() => {
          showAlertDiscountPermission = false
          if (typeIfUndefined == 'promo')
            usePromoForBarcode = usePromos
          else if (typeIfUndefined == 'card')
            useCardForBarcode = useCustomerCard
        }">Continua</v-btn>
        <v-btn text color="error" @click="() => {
          discountPermissionDialog = false;
          if (useCardForBarcode || typeIfUndefined == 'card') {
            useCustomerCard = !useCustomerCard
          }
          else if (usePromoForBarcode || typeIfUndefined == 'promo') {
            usePromos = !usePromos
          } 
        }">Chiudi</v-btn>
      </template>
    </StandardDialog>

    <StandardDialog 
      v-model="checkPaymentCredits" 
      title="Gestione Pagamenti Sospesi" 
      :dialog-height="null"
      dialog-width="100vh"
    >
      <div v-if="!showBarcodeCredits">
        <v-row>
          <v-col class="d-flex flex-column" cols="1">
            <v-icon large color="red" style="margin-top: 2px">
              mdi-alert
            </v-icon>
          </v-col>
          <v-col class="d-flex flex-column">
            <div class="text-left" style="font-size: 1.2em; margin-top: 10px">
              <span style="font-size: large">Sono presenti i seguenti pagamenti sospesi, si
                desidera pagarli?</span>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <TypeDataTable :headers="paymentCreditsHeaders" :items="billPaymentCredits" :show-select="false"
              :show-actions="false" :disable-pagination="true" fixed-header>
              <template v-slot:custom-documentNumber="{item}">
                <span>
                  {{item.documentNumber}}
                </span>
              </template>
              <template v-slot:custom-type="{item}">
                <span>
                  {{item.type === 'cashDesk' ? 'Conto in Cassa' :
                  item.type === 'customerCards_transaction' ? 'Ricarica Tessera' : undefined}}
                </span>
              </template>
              <template v-slot:custom-services="{item}">
                <span v-for="bill in item.bills" :key="bill.id">
                  <span v-for="billService in bill.billServices" :key="billService.id">
                    {{billService.service.name}}
                    (
                    <span v-for="billServiceOperator in billService.billServiceOperators" :key="billServiceOperator.id">
                      {{billServiceOperator.operator.firstname}},
                    </span>
                    ),
                  </span>
                  <span v-for="billItem in bill.billItems" :key="billItem.id">
                    {{billItem.item.description}},
                  </span>
                </span>
              </template>
            </TypeDataTable>
          </v-col>
        </v-row>
      </div>
      <div v-if="showBarcodeCredits">
        <v-row>
          <v-col>
            <ManualBarcodeInput v-model="skipCreditsBarcode" @confirm="skipPendingCredits">
            </ManualBarcodeInput>
          </v-col>
        </v-row>
      </div>
      <template v-slot:footer>
        <v-spacer></v-spacer>
        <v-btn v-if="!showBarcodeCredits" color="default" text @click="payPendingCredits">
          Paga
        </v-btn>
        <v-btn v-if="!showBarcodeCredits" color="default" text @click="showBarcodeCredits = true">
          Salta Sospesi
        </v-btn>
        <v-btn v-if="showBarcodeCredits" color="default" text @click="showBarcodeCredits = false">
          Indietro
        </v-btn>
        <v-btn color="error" text @click="closePaymentCreditsDialog">
          Chiudi
        </v-btn>
      </template>
    </StandardDialog>
    <StandardDialog v-model="promoDialog" :dialog-height="null" :title="promoTitle" dialog-max-width="1000px">
      <CustomerPromosForm v-if="promoDialog" :customer="localCustomer" :promos-customers.sync="promoCustomers"
        @update:promos-customers="handleUpdatePromoCustomers" @close-dialog="promoDialog = false" :show-custom-bar="false"></CustomerPromosForm>

      <template v-slot:footer-actions>
        <div>
          <v-spacer></v-spacer>
          <v-btn text color="default" @click="continuePayment">
            PROSEGUI
          </v-btn>
          <v-btn text color="error" @click="promoDialog = false">
            Chiudi
          </v-btn>
        </div>
      </template>
    </StandardDialog>
    <MenuDialog 
      :billId="customerBillId"
      :customer-id="!!bill && !!bill.customer ? bill.customer.id
        : undefined"
      hideCloseTable 
      :selectedTable="undefined" 
      :openMenu="openBarDialog" 
      @close="closeMenuDialog"
      :go-back="'Homepage'" 
      :return-bill="bill"
    >
    </MenuDialog>
    
    <SimplePopUp
      v-model="gdprPopUp"
      :alert-text="gdprPopUpText"
      :dismissible="false"
      :width="this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        ? '50vw' : '30vw'"
    >
      <template v-slot:footer>
        <v-row class="pt-7 d-flex justify-space-around">
          <v-btn
            style="min-width: 30%;"
            color="error"
            @click="gdprRejected"
          >
            Rifiuta
          </v-btn>
          <v-btn
            style="min-width: 30%;"
            color="success"
            @click="gdprSignature"
          >
            Conferma
          </v-btn>
        </v-row>
      </template>
    </SimplePopUp>
  </div>
</template>

<script>
import cashDeskService from '@/services/cashDesks/cashDesks.service.js'
import operatorService from '@/services/operators/operators.service.js'
import customersService from '@/services/customers/customers.service.js'
import ExpandibleAmount from '@/components/homepage/sections/ExpandibleAmount.vue';
import StandardDialog from '@/components/common/StandardDialog.vue';
import CashDeskSelector from '@/components/common/CashDeskSelector.vue';
import OperatorAvatar from '@/components/common/OperatorAvatar.vue';
import BarcodeDrawer from '@/components/common/BarcodeDrawer.vue';
import ManualBarcodeInput from '@/components/common/ManualBarcodeInput.vue';
import billServiceCalculator from '@/services/bills/billService.calculator.js';
import DateTimeFormattor from '@/components/common/DateTimeFormattor.vue';
import TypeDataTable from '@/components/common/TypeDataTable.vue';
import SimplePopUp from '@/components/common/SimplePopUp.vue'
import Printer from '@/services/devices/rtPrinter';
import CustomerPromosForm from "@/components/areas/registries/customers/promos/CustomerPromosForm.vue";
import MenuDialog from '@/components/bar/daily-reservation/MenuDialog.vue';
import billService from "@/services/bills/bills.service";
import schedulePaymentsSettingsService from '@/services/schedulePaymentsSettings/schedulePaymentsSettings.service.js'
import { cloneDeep } from 'lodash'
import Vue from 'vue'
import cookiesService from "@/services/cookies.service"


export default {
  name: "CashSection",
  components: {
    ExpandibleAmount,
    StandardDialog,
    CashDeskSelector,
    OperatorAvatar,
    BarcodeDrawer,
    DateTimeFormattor,
    ManualBarcodeInput,
    TypeDataTable,
    CustomerPromosForm,
    MenuDialog,
    SimplePopUp,
  },
  data: function () {
    return {
      useCustomerCard: false,
      usePromos: false,
      expanded: undefined,
      maxActionWidth: undefined,
      resizeHandler: undefined,
      cashDeskOpening: false,
      openedCashDesk: undefined,
      cashDeskDialog: false,
      manualBarcodeInsert: false,
      operatorFromBadge: undefined,
      selectedCashDesk: undefined,
      insertedBarcode: undefined,

      customerCardRemaining: undefined,
      customerCardCreditGhost: false,
      total: undefined,
      totalDiscounts: undefined,
      subTotal: undefined,
      toBePaid: undefined,
      newCustomerCardRemaining: undefined,

      billPaymentCredits: [],
      checkPaymentCredits: false,
      showBarcodeCredits: false,
      skipCreditsBarcode: undefined,
      paymentCreditSkipped: false,

      discountPermissionDialog: false,
      allowDiscountBarcode: false,
      usePromoForBarcode: undefined,
      useCardForBarcode: undefined,
      typeIfUndefined: undefined,
      showAlertDiscountPermission: true,

      promoTitle: undefined,
      promoDialog: false,
      promoCustomers: [],
      localCustomer: undefined,
      viewBar: undefined,

      paymentCreditsHeaders: [
        {
          text: "Codice Scontrino",
          value: "documentNumber",
          type: "custom"
        },
        {
          text: "Prezzo",
          value: "amount",
          type: "price",
        },
        {
          text: "Data",
          value: "openedAt",
          type: "date",
        },
        {
          text: "Tipo conto",
          value: "type",
          type: "custom"
        },
        {
          text: "Servizi/Prodotti",
          value: "services",
          type: "custom"
        },
      ],

      selectedServices: [],
      discountedBillServices: [],
      discountedBillItems: [],
      discountedBillEntities: [],
      closureCashDeskDialog: false,
      customerCardEntities: undefined,
      closeCashDeskBarcode: undefined,

      openBarDialog: false,
      customerBillId: undefined,
      schedule: undefined,
      barBill: undefined,
      barBillTotal: undefined,

      forcedCloseCashDask: undefined,
      forceClosureCashDeskDialog: false,
      forceCloseCashDeskBarcode: undefined,

      showPromoCustomer: undefined,
      showPromoNewCustomer: undefined,
      showPromoBirthdayCustomer: undefined,

      gdprPopUp: false,
      gdprPopUpText: "Il GDPR del cliente risulta non firmato! Firmare per consentire le operazioni di Marketing",
      checkCustomerGdpr: undefined
    }
  },
  props: {
    bus: {
      type: Object,
      default: function () {
        return new Vue()
      }
    },
    bill: {
    },
    scanning: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  mounted: function () {
    this.$emit('update:scanning', this.cashDeskDialog)
    this.getOpenedCashDesk()

    if (this.$refs.actionColumn) {
      this.calculateWidth()
    }

    this.resizeHandler = () => {
      this.calculateWidth()
    }
    this.resizeHandler = this.resizeHandler.bind(this)
    window.addEventListener('resize', this.resizeHandler)

    this.initializeBillCalculator()
    this.initializeBillPaymentCredits()

    this.billServiceCalculatorUpdateHandler = () => {
      this.customerCardRemaining = billServiceCalculator.cardRemaining
      this.customerCardCreditGhost = billServiceCalculator.customerCardCreditGhost
      this.total = billServiceCalculator.total
      this.discountedBillServices = cloneDeep(billServiceCalculator.discountedBillServices)
      this.discountedBillItems = cloneDeep(billServiceCalculator.discountedBillItems)
      this.discountedBillEntities = cloneDeep(billServiceCalculator.discountedBillEntities)
      this.totalDiscounts = billServiceCalculator.discount
      this.subTotal = billServiceCalculator.subTotal < 0 ? 0 : billServiceCalculator.subTotal
      this.toBePaid = billServiceCalculator.toBePaid
      this.newCustomerCardRemaining = billServiceCalculator.newCardRemaining
    }

    this.billServiceCalculatorUpdateHandler = this.billServiceCalculatorUpdateHandler.bind(this)
    this.billServiceCalculatorUpdateHandler()
    billServiceCalculator.on('update', this.billServiceCalculatorUpdateHandler)
    this.bus.$on('selected-services', this.returnSelectedServices)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.resizeHandler)
    billServiceCalculator.off(this.billServiceCalculatorUpdateHandler)
  },
  methods: {
    handleUpdatePromoCustomers(newPromosCustomers) {
      this.$set(this.localCustomer, 'promosCustomers', newPromosCustomers)
      this.$emit('update:customer', this.localCustomer)
    },
    handleChangePromo(usePromo) {
      if (this.bill.billServices.some((billService) => { return !!billService.editPriceType && !!billService.editPriceValue })) {
        this.discountPermissionDialog = true
        this.showAlertDiscountPermission = true
        this.typeIfUndefined = "promo"
      } else if (this.bill.billItems.some((billItem) => { return !!billItem.editPriceType && !!billItem.editPriceValue })) {
        this.discountPermissionDialog = true
        this.showAlertDiscountPermission = true
        this.typeIfUndefined = "promo"
      } else {
        this.setUsePromos(usePromo)
      }

      if(!!this.bill) {
        let billsThatUsePromo = JSON.parse(localStorage.getItem('use-promo-bills'))
        if(billsThatUsePromo !== undefined && billsThatUsePromo !== null) {
          if (usePromo && !billsThatUsePromo.includes(this.bill.id)) {
            billsThatUsePromo.push(this.bill.id)
          } else if(!usePromo) {
            billsThatUsePromo = billsThatUsePromo.filter((id) => id !== this.bill.id)
          }
        } else {
          if(usePromo) {
            billsThatUsePromo = [this.bill.id]
          } else billsThatUsePromo = []
        }

        localStorage.setItem('use-promo-bills', JSON.stringify(billsThatUsePromo))
      }
    },
    handleChangeCard(useCard) {
      if (this.bill.billServices.some((billService) => { return !!billService.editPriceType && !!billService.editPriceValue })) {
        this.discountPermissionDialog = true
        this.showAlertDiscountPermission = true
        this.typeIfUndefined = "card"
      } else if (this.bill.billItems.some((billItem) => { return !!billItem.editPriceType && !!billItem.editPriceValue })) {
        this.discountPermissionDialog = true
        this.showAlertDiscountPermission = true
        this.typeIfUndefined = "card"
      } else {
        this.setUseCustomerCard(useCard)
      }

      if(!!this.bill) {
        let billsThatUseCard = JSON.parse(localStorage.getItem('use-card-bills'))
        if(billsThatUseCard !== undefined && billsThatUseCard !== null) {
          if (useCard && !billsThatUseCard.includes(this.bill.id)) {
            billsThatUseCard.push(this.bill.id)
          } else if(!useCard) {
            billsThatUseCard = billsThatUseCard.filter((id) => id !== this.bill.id)
          }
        } else {
          if(useCard) {
            billsThatUseCard = [this.bill.id]
          } else billsThatUseCard = []
        }

        localStorage.setItem('use-card-bills', JSON.stringify(billsThatUseCard))
      }
    },
    setUsePromos(usePromo) {
      billServiceCalculator.usePromos(usePromo)
    },
    setUseCustomerCard(useCard) {
      billServiceCalculator.useCard(useCard)
    },
    initializeBillCalculator() {
      // this.useCustomerCard = false
      billServiceCalculator.setCustomerCard(this.activeCard)
      billServiceCalculator.setPromosCustomers(this.activePromosCustomers)
      if (!!this.bill) {
        billServiceCalculator.setBill(this.bill)
      }
      this.setUseCustomerCard(this.useCustomerCard)
      this.setUsePromos(this.usePromos)
    },
    initializeBillPaymentCredits() {
      if (this.billPresent) {
        if (!!this.bill.billCustomers[0].customer && !!this.bill.billCustomers[0].customer.id) {
          customersService.getPaymentCredits(this.bill.billCustomers[0].customer, [
            { type: 'custom', operator: 'custom', field: 'status', name: 'Stato', label: 'Stato', color: "", value: ['beginning'], icon: "mdi-list-status" },
          ]).then((results) => {
            this.billPaymentCredits = results.rows.filter(pc => pc.status != 'finished')
          })
        }
      }
    },
    closePaymentCreditsDialog() {
      this.checkPaymentCredits = false
      this.showBarcodeCredits = false
    },
    payPendingCredits() {
      this.checkPaymentCredits = false
      this.bus.$emit('open-credits')
    },
    skipPendingCredits() {
      operatorService.canPerformOperation(this.skipCreditsBarcode, "SkipSuspendedPayments").then((result) => {
        if (result) {
          this.checkPaymentCredits = false
          this.showBarcodeCredits = false
          this.paymentCreditSkipped = true
          this.openCashDialog(false)
        } else {
          alert("Non hai i permessi per eseguire questa azione!")
        }
      }).catch(() => {
        alert("Badge non riconosciuto")
      })
    },
    handleConfirmDiscount() {
      operatorService.canPerformOperation(this.allowDiscountBarcode, "ApplyDiscounts").then((result) => {
        if (result) {
          if (this.usePromoForBarcode != undefined)
            this.setUsePromos(this.usePromoForBarcode)
          else if (this.useCardForBarcode != undefined)
            this.setUseCustomerCard(this.useCardForBarcode)

          this.usePromoForBarcode = undefined
          this.useCardForBarcode = undefined
          this.discountPermissionDialog = false
        } else {
          this.$delegates.snackbar('Non hai i permessi per eseguire questa azione!')
        }
      }).catch(() => {
        this.$delegates.snackbar('Badge non riconosciuto')
      })
    },
    getOpenedCashDesk() {
      cashDeskService.getOpened().then((openedCashDesk) => {
        this.openedCashDesk = openedCashDesk
      })
    },
    calculateWidth() {
      if (!!this.$refs.actionColumn) {
        this.maxActionWidth = this.$refs.actionColumn.offsetWidth - 20 + 'px'
      }
    },
    toggleExpanded(name) {
      if (this.expanded == name) {
        this.expanded = undefined
      } else {
        this.expanded = name
      }
    },
    async openCashDialog(rejectedGdpr = false) {
      const ctrl = await billService.getBillFromCustomer(this.bill.billCustomers[0].customer.id)
      if (!ctrl)
        if (this.bill.billServices.length == 0 && this.bill.billItems.length == 0) {
          this.$delegates.snackbar("Inserire almeno un servizio o un prodotto per proseguire");
        } else if (this.checkServiceOperators == true) {
          this.$delegates.snackbar("Tutti i servizi devono avere un operatore assegnato");
        } else if (this.checkItemOperators == true) {
          this.$delegates.snackbar("Tutti i prodotti devono avere un operatore assegnato");
        } else if (!this.paymentCreditSkipped && this.bill.billCustomers.some((billCustomer) => billCustomer.customer.billPaymentCredits.length > 0)) {
          customersService.getPaymentCredits(this.bill.billCustomers[0].customer, [
            { type: 'custom', operator: 'custom', field: 'status', name: 'Stato', label: 'Stato', color: "", value: ['beginning'], icon: "mdi-list-status" },
            {
              type: 'custom', operator: 'custom', field: 'billStatus', name: 'Stato Scontrino', label: 'Stato Scontrino', color: "", value: {
                billServiceStatus: 'notPaid',
                billItemStatus: 'notPaid',
              }, icon: "mdi-list-status"
            }
          ]).then((results) => {
            let count = (results.rows.filter(pc => pc.status != 'finished')).length
            if (count > 0)
              this.checkPaymentCredits = true
            else {
              this.bill.billCustomers[0].customer.billPaymentCredits = []
              this.openCashDialog()
            }
          })
        } else if ((this.bill.billServices.filter((el) => { return el.isCompleted === false || el.isCompleted == null })).length > 0) {
          this.$delegates.snackbar("I servizi devono essere completati per proseguire");
        } else if ((this.bill.billItems.filter((el) => { return el.isCompleted === false || el.isCompleted == null })).length > 0) {
          this.$delegates.snackbar("I prodotti devono essere completati per proseguire");
        } else if (((this.bill.billServices.filter((el) => { return el.ghost === true })).length > 0 || (this.bill.billItems.filter((el) => { return el.ghost === true })).length > 0) &&
          (this.bill.billServices.filter((el) => { return el.ghost === true })).length + (this.bill.billItems.filter((el) => { return el.ghost === true })).length != this.bill.billServices.length + this.bill.billItems.length) {
          this.$delegates.snackbar("Tutti i servizi/prodotti devono essere in concordato");
        } else {

          if (!!this.checkCustomerGdpr && !rejectedGdpr && (!this.bill.customer.consentForm || !this.bill.customer.consentForm.signature)) {
            this.gdprPopUp = true
          }

          else if (!this.checkCustomerGdpr || (!!rejectedGdpr || (!!this.bill.customer.consentForm || !!this.bill.customer.consentForm.signature))) {
            if (this.getAge(this.bill.customer.birthday) < 18 && !!this.showPromoMinorCustomer) {
              this.localCustomer = this.bill.customer
              this.promoTitle = 'Questo cliente ha ' + this.getAge(this.bill.customer.birthday) + ' anni!! Vuoi assegnargli una promozione?'
              this.promoDialog = true
            } else if (!!this.bill.billCustomers[0].customer.newCustomer && !!this.showPromoNewCustomer) {
              this.localCustomer = this.bill.customer
              this.promoTitle = 'Questo cliente risulta come NUOVO CLIENTE!! Vuoi assegnargli una promozione?'
              this.promoDialog = true
            } else if (!!this.activePromosCustomers && this.activePromosCustomers.length === 0 && !!this.showPromoCustomer) {
              this.localCustomer = this.bill.customer
              this.promoTitle = 'Questo cliente non ha nessuna promozione attiva!! Vuoi assegnargliene una?'
              this.promoDialog = true
            }  else {
              if (!this.bill.customer.birthday || !this.bill.customer.email) {
                this.$delegates.snackbar("Compilare i dati in anagrafica: data di nascita e/o mail")
              }
              this.paymentCreditSkipped = false
              this.continuePayment();
            }
          }
        }
      else
        this.barCustomer()
    },
    gdprRejected() {
      this.gdprPopUp = false
      this.openCashDialog(true)
    },
    gdprSignature() {
      this.$router.push({
        name: "CustomersRegistryEditForm",
        params: {
          id: this.bill.customer.id,
        },
        query: {
          pathToGoBack: this.$router.resolve({ name: "Homepage" }).href,
        },
      });
    },
    getAge(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    async isScheduled() {
      let total = (await this.billSummary()).amount

      let schedules = []
      schedules = await schedulePaymentsSettingsService.list()
      for (const schedule of schedules.rows) {
        if (total > Number(schedule.amountFrom) && total < Number(schedule.amountTo)) {
          this.schedule = schedule
          return true
        }
      }

      return false
    },
    continuePayment() {
      this.$router.push({
        name: 'PayBill',
        params: {
          billId: this.bill.id,
          bill: this.bill,
          promoUsed: cloneDeep(billServiceCalculator.promosCustomerUsed),
          promoRemaining: cloneDeep(billServiceCalculator.remainingPromo),
          customerCard: this.useCustomerCard ? this.activeCard : undefined,
          cashDeskLog: this.openedCashDesk.cashDeskLog.id,
          customerCardCreditGhost: this.customerCardCreditGhost,
        },
        query: {
          useCustomerCard: this.useCustomerCard,
          usePromos: this.usePromos,
        }
      })
    },
    async billSummary() {
      let barBill = await billService.getBillFromCustomer(this.bill.customer.id)
      if (this.discountedBillServices.length == 0 && this.discountedBillItems.length == 0 && !barBill) {
        this.$delegates.snackbar("Inserire almeno un servizio o un prodotto per proseguire");
      } else {
        const allServices = this.discountedBillServices.map(billService => {
          let taxForSaloon = billService.tax !== undefined && billService.tax !== null ? billService.tax : undefined
          if (taxForSaloon === undefined || taxForSaloon === null) taxForSaloon = '22'

          let object = {
            name: billService.service.name,
            additionalDescription: billService.billServiceOperators[0] ? billService.billServiceOperators[0].operator.firstname : "",
            price: billService.service.price,
            quantity: billService.quantity,
            discount: billService.discount ? billService.discount : 0,
            discountDescription: billService.discountDescription ? billService.discountDescription : "",
            // tax: billService.service.departments.some(d=> d.description == 'Bar')  ? "10%" :"22%"
            tax: taxForSaloon + '%',
          }

          if (!!billService.editPriceType && !!billService.editPriceValue) {
            object.price = billServiceCalculator.calculateEditedPrice(billService)
          }

          return object
        }).filter(el => !!el.quantity)

        const allItems = this.discountedBillItems.map(billItem => {
          let taxForSaloon = billItem.tax !== undefined && billItem.tax !== null ? billItem.tax : undefined
          if (taxForSaloon === undefined || taxForSaloon === null) taxForSaloon = '22'

          let object = {
            name: billItem.item.description,
            additionalDescription: "",
            price: billItem.item.priceSelling,
            quantity: billItem.quantity,
            discount: billItem.discount ? billItem.discount : 0,
            discountDescription: billItem.discountDescription ? billItem.discountDescription : "",
            tax: taxForSaloon + '%',
          }

          if (!!billItem.editPriceType && !!billItem.editPriceValue) {
            object.price = billServiceCalculator.calculateEditedItemPrice(billItem)
          }

          return object
        }).filter(el => !!el.quantity)


        let allBarItem = []
        let allBarService = []
        let barTotal = 0
        if (!!barBill) {
          allBarItem = barBill.billItems.map(barItem => {
            let taxForBar = barItem.tax !== undefined && barItem.tax !== null ? barItem.tax : undefined
            if (taxForBar === undefined || taxForBar === null) taxForBar = '22'

            let object = {
              name: barItem.item.description,
              additionalDescription: "",
              price: barItem.item.priceSelling,
              quantity: barItem.quantity,
              discount: 0,
              discountDescription: "",
              tax: "10,00 %"
            }
            barTotal += Number(barItem.item.priceSelling) * Number(barItem.quantity)
            return object
          }).filter(el => !!el.quantity)

          allBarService = barBill.billServices.map(barService => {
            let taxForBar = barService.tax !== undefined && barService.tax !== null ? barService.tax : undefined
            if (taxForBar === undefined || taxForBar === null) taxForBar = '22'

            let object = {
              name: barService.service.name,
              additionalDescription: "",
              price: barService.service.price,
              quantity: barService.quantity,
              discount: 0,
              discountDescription: "",
              tax: "10,00 %"
            }
            barTotal += Number(barService.service.price) * Number(barService.quantity)
            return object
          }).filter(el => !!el.quantity)
        }

        const totalServicesAndItems = [
          ...allServices,
          ...allItems,
          ...allBarItem,
          ...allBarService
        ]

        let localTotalGhost = billServiceCalculator.totalGhost != undefined && billServiceCalculator.totalGhost > 0 ?
          billServiceCalculator.discount != undefined ? Number(billServiceCalculator.totalGhost) - Number(billServiceCalculator.discount) : billServiceCalculator.ghost
          : undefined
        await Printer.printSummary({
          amount: !!localTotalGhost ? localTotalGhost : billServiceCalculator.toBePaid + barTotal,
          items: totalServicesAndItems,
          discounts: this.usePromos ? [
            {
              description: 'Promozione Utilizzata',
              amount: Number(billServiceCalculator.discount)
            }
          ] : undefined,
        }, false, "summary cashSection")

        return {
          amount: !!localTotalGhost ? localTotalGhost : billServiceCalculator.toBePaid + barTotal,
          items: totalServicesAndItems,
          discounts: this.usePromos ? [
            {
              description: 'Promozione Utilizzata',
              amount: Number(billServiceCalculator.discount)
            }
          ] : undefined,
        }
      }
    },
    openCashDeskDialog() {
      this.cashDeskDialog = true
      this.operatorFromBadge = undefined
      this.selectedCashDesk = undefined
      this.insertedBarcode = undefined
      this.$emit('update:scanning', this.cashDeskDialog)
    },
    handleConfirmBarcode() {
      operatorService.getByBarcode(this.insertedBarcode).then((results) => {
        this.operatorFromBadge = results
        this.manualBarcodeInsert = false
        this.tryOpenCashDesk()
      })
    },
    handleCashDeskSelectorInput(cashDesk) {
      if (!!cashDesk) {
        this.tryOpenCashDesk()
      }
    },
    tryOpenCashDesk() {
      if (!!this.operatorFromBadge && !!this.selectedCashDesk) {
        let confirmed = confirm(`${this.operatorFromBadge.firstname} ${this.operatorFromBadge.lastname}, vuoi davvero aprire la cassa ${this.selectedCashDesk.name}?`)

        if (confirmed) {
          this.cashDeskOpening = true
          cashDeskService.open(this.selectedCashDesk, this.operatorFromBadge).then((results) => {
            this.getOpenedCashDesk()
            this.cashDeskOpening = false
            this.cashDeskDialog = false
          })
        }
      }
    },
    handleCashDeskClose(item) {
      this.forcedCloseCashDask = item
      this.forceClosureCashDeskDialog = true
    },
    forceCloseCashDesk() {
      operatorService.canPerformOperation(this.forceCloseCashDeskBarcode, "CloseCashDesk", undefined, true)
        .then((result) => {
          if (result.result) {
            cashDeskService.close(this.forcedCloseCashDask, result.operator, true).then(() => {
              this.getOpenedCashDesk()
              this.forceClosureCashDeskDialog = false
              this.forceCloseCashDeskBarcode = undefined
              this.bus.$emit('reload')
            })
          }
          else {
            this.$delegates.snackbar("Non si hanno i permessi adeguati");
          }
        })
    },
    closeCashDesk() {
      if (this.cashDeskOpened) {
        operatorService.canPerformOperation(this.closeCashDeskBarcode, "CloseCashDesk")
          .then((result) => {
            if (result || this.openedCashDesk.operator.barcode == this.closeCashDeskBarcode) {
              cashDeskService.close(this.openedCashDesk, this.openedCashDesk.operator).then(() => {
                this.getOpenedCashDesk()
                this.closureCashDeskDialog = false
                this.closeCashDeskBarcode = undefined
              })
            }
            else {
              this.$delegates.snackbar("Il badge non corrisponde a quello di apertura cassa o non si hanno i permessi adeguati");
            }

          })
      }
    },
    returnSelectedServices(selectedServices) {
      if (selectedServices)
        this.selectedServices = selectedServices
    },
    async barCustomer() {
      try {
        const ctrl = await billService.getBillFromCustomer(this.bill.customer.id)

        if (!ctrl) {
          //let billCreated = await billService.createBillFromRestaurant(undefined,undefined,true)
          let billCreated = await billService.addCustomersToRestaurant(this.bill.customer.id, undefined, true)
          this.customerBillId = Number(billCreated.id)

          this.openBarDialog = true
        }
        else {
          this.customerBillId = Number(ctrl.id)

          this.openBarDialog = true
        }
      } catch (error) {
        console.log(error)
      }
    },
    async updateBillBar() {
      if (!!this.bill) {
        const ctrl = await billService.getBillFromCustomer(this.bill.customer.id)
        if (!!ctrl) {
          this.barBill = ctrl
          this.barBillTotal = this.barBill.billServices.map(el => el.service.price * el.quantity).reduce((a, b) => a + b, 0)
        }
        else {
          this.barBill = undefined
          this.barBillTotal = undefined
        }
      } else {
        this.barBill = undefined
        this.barBillTotal = undefined
      }
    },
    closeMenuDialog() {
      this.openBarDialog = false
      this.updateBillBar()
    }
  },
  barcode: {
    scan(barcode) {
      if (!this.disabled && barcode.length > 3) {
        if (this.cashDeskDialog) {
          operatorService.getByBarcode(barcode).then((results) => {
            this.operatorFromBadge = results
            this.tryOpenCashDesk()
          })
        }
        else if (this.discountPermissionDialog && !this.showAlertDiscountPermission) {
          this.allowDiscountBarcode = barcode
          this.handleConfirmDiscount()
        }
        else if (this.closureCashDeskDialog) {
          this.closeCashDeskBarcode = barcode
          this.closeCashDesk();
        }
      }
    }
  },
  computed: {
    billPresent() {
      return !!this.bill
    },
    providedServicePresent() {
      return this.billPresent && !!this.bill.billServices && Array.isArray(this.bill.billServices)
    },
    activeCard() {
      if (this.billPresent && !!this.bill.billCustomers && !!this.bill.billCustomers[0] && !!this.bill.billCustomers[0].customer.activeCustomerCards && !!this.bill.billCustomers[0].customer.activeCustomerCards[0]) {
        return this.bill.billCustomers[0].customer.activeCustomerCards[0]
      } else {
        return undefined
      }
    },
    activePromosCustomers() {
      if (this.billPresent && !!this.bill.customer && !!this.bill.customer.promosCustomers) {
        return this.bill.customer.promosCustomers
      } else {
        return undefined
      }
    },
    cashDeskOpened() {
      return !!this.openedCashDesk
    },
    checkServiceOperators() {
      return this.bill.billServices.some((billService) => billService.billServiceOperators.length === 0)
    },
    checkItemOperators() {
      return this.bill.billItems.some((billItem) => billItem.billItemOperators.length === 0)
    },
    barPrice() {
    }
  },
  watch: {
    bill(value) {
      if (!!value) {
        const billsThatUsesPromo = JSON.parse(localStorage.getItem('use-promo-bills'))
        const billsThatUsesCard = JSON.parse(localStorage.getItem('use-card-bills'))

        if (billsThatUsesCard !== undefined && billsThatUsesCard !== null) {
          const useCards = billsThatUsesCard.includes(value.id)
          this.useCustomerCard = useCards
        }

        if (billsThatUsesPromo !== undefined && billsThatUsesPromo !== null) {
          const usePromos = billsThatUsesPromo.includes(value.id)
          this.usePromos = usePromos
        }
      }

      this.initializeBillCalculator()
      this.initializeBillPaymentCredits()
      this.$nextTick(() => {
        this.calculateWidth()
      })
      this.updateBillBar()
    },
    usesCustomerCard(newVal) {
      if (newVal == true && (this.customerCardRemaining == 0 || this.customerCardRemaining == null)) {
        this.$delegates.snackbar("Credito Tessera esaurito, ricaricare la tessera per usufruire degli sconti associati");
        this.$nextTick(() => {
          this.useCustomerCard = false
          this.setUseCustomerCard(false)
        })
      }
    },
    cashDeskDialog(newVal) {
      this.$emit('update:scanning', newVal)
    },
    closureCashDeskDialog(newVal) {
      this.$emit('update:scanning', newVal)
    },
    discountPermissionDialog(newVal) {
      this.$emit('update:scanning', newVal)
    }
  },
  licenseSettings: {
    viewBar: {
      bind: "viewBar"
    }
  },
  settings: {
    showPromoCustomer: {
      bind: "showPromoCustomer"
    },
    showPromoNewCustomer: {
      bind: "showPromoNewCustomer"
    },
    showPromoMinorCustomer: {
      bind: "showPromoMinorCustomer"
    },
    checkCustomerGdpr: {
      bind: "checkCustomerGdpr"
    }
  }
};
</script>

<style>
  .back-color-gradient {
    background-image: linear-gradient(#8a77e2, #50bfe1);
  }
</style>