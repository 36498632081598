var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.cashDesks.length == 0)?_c('div',{staticClass:"text-center font-weight-thin"},[_vm._v(" Nessuna cassa ")]):_c('div',[_c('ResponsiveCardSelector',{attrs:{"items":_vm.cashDesks,"card-max-height":"150","card-min-height":"150","card-max-width":"150","card-min-width":"150","mandatory":_vm.mandatory,"loading":_vm.localLoading || _vm.loading,"disabled":_vm.disabled,"return-object":_vm.returnObject,"card-disabled":(item) => {
        if(_vm.disableClosed) {
          return item.status == 'closed'
        } else if(_vm.disableOpened) {
          return item.status == 'opened'
        } else {
          return false
        }
      }},on:{"input":function($event){return _vm.$emit('input', _vm.localValue)},"click":function($event){return _vm.$emit('click', $event)}},scopedSlots:_vm._u([{key:"item",fn:function({item, cardMinHeight}){return [_c('div',{staticClass:"pa-2 d-flex flex-column justify-center align-center",style:({'min-height': cardMinHeight.indexOf('px') != -1 ? cardMinHeight : cardMinHeight + 'px'})},[_c('v-btn',{staticStyle:{"position":"absolute","right":"0.5px","top":"0.5px"},attrs:{"icon":"","disabled":item.status == 'closed',"color":"red"},on:{"click":function($event){return _vm.$emit('cash-desk-close', item)}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1),_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"text-caption font-weight-thin"},[(item.enabled)?_c('span',[_vm._v(" "+_vm._s(_vm.$translations.t('enums.cashDesks.status.' + item.status))+" "),_c('br'),(item.status == 'opened')?_c('span',[_vm._v(_vm._s(item.cashDeskLog[item.cashDeskLog.length-1].operator.firstname))]):_vm._e()]):_c('span',[_vm._v("disabilitata")])])],1)]}}]),model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }