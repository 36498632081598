<template>
  <StandardDialog
    v-model="promoDialog"
    title="Nuova Promozione"
    :routeFather="pathToGoBack"
    @input="$emit('update:open-dialog', $event)"
    dialog-max-width="1000px"
    :dialog-height="null"
  >
    <v-card flat>
      <GeneralForm
        :bus="bus"
      >
      </GeneralForm>
    </v-card>
    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="error"
        @click="close"
      >Annulla</v-btn>
      <v-btn
        text
        color="default"
        :disabled="!formValid"
        @click="submitForm"
      >Salva</v-btn>
    </template>
  </StandardDialog>
</template>

<script>
import Vue from 'vue'
import StandardDialog from "@/components/common/StandardDialog.vue";
import GeneralForm from "@/components/areas/payments/promos/GeneralDiscountFormTab.vue"
import promosService from '@/services/promos/promos.service.js'
import promoForm from '@/services/promos/promo.form.js'

export default {
  name: "PromosNewForm",
  components: {
    StandardDialog,
    GeneralForm
  },
  data: function() {
    return {
      tab: null,
      promoDialog: this.openDialog,
      promoTabs: [
        {
          key: 0,
          title: "Generale",
          name: "general",
          component: "GeneralForm",
          props: {
            nameGiftCard: this.nameGiftCard,
            descriptionGiftCard: this.descriptionGiftCard,
            customerGiftCard: this.customerGiftCard
          }
        }
      ],
      promo: undefined,
      originalUrl: window.location.pathname
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)

    let self = this
    promoForm.resetPromo()
    promoForm.on('update', function(data) {
      self.promo = data.promo
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    pathToGoBack: {
      type: String,
      default: "/payments/promos/list"
    },
    tabName: {
      type: String,
      default: 'general'
    },
    custom: {
      type: Boolean,
      default: false
    },
    nameGiftCard: {
      type: String,
      default: "",
    },
    descriptionGiftCard: {
      type: String,
      default: "",
    },
    customerGiftCard: {
      type: String,
      default: "",
    },
    close:{
      type: Function,
      default: ()=>{}
    },
    bus:{
      type: Object,
      default: function() {
        return new Vue()
      }
    }
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.promoTabs.length; i++) {
        if(this.promoTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      if(this.custom) {
        promosService.createCustom(this.promo).then((newPromo) => {
          this.close()
          this.$emit('submit', newPromo)
        })
      } else {
        if(this.promo.status == "active" && (!this.promo.activeFrom)) {
          this.$delegates.snackbar("Inserire la data di inizio promo");
          return;
        } else if (this.promo.status == "activeInDates" && (!this.promo.activeTo) && (!this.promo.activeFrom)) {
          this.$delegates.snackbar("Inserire il range di promo");
          return;
        } else if (!this.promo.gainType) {
          this.$delegates.snackbar("Selezionare come il cliente guadagna la promo");
          return;
        } else if (this.promo.gainType == "target" && this.promo.allServicesTarget == false && this.promo.targetItems.length == 0 ) {
          this.$delegates.snackbar("Selezionare l'obiettivo del cliente ");
          return;
        } else if (this.promo.itemGained == "services" && this.promo.allServicesGained == false && this.promo.gainedItems.length == 0 ) {
          this.$delegates.snackbar("Selezionare che servizi guadagna cliente ");
          return;
        } else if (!this.promo.useTerms) {
          this.$delegates.snackbar("Selezionare come il cliente userà la promo");
          return;
        } else if ((this.promo.useTerms == 'oneShotRange' || this.promo.useTerms == 'range') && !this.promo.startDate) {
          this.$delegates.snackbar("Inserire correttamente i range di utilizzo");
          return;
        } else {
          promosService.create(this.promo).then((newPromo) => {
            this.close()
            this.$emit('submit', newPromo)
          })
        }
      }
      this.bus.$emit('resetDiscount')
    }
  },
  watch: {
    openDialog(newVal) {
      this.promoDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.promoTabs[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    }
  },
  computed: {
    formValid() {
      return !!this.promo && ((!this.promo.discount && !!this.promo.amount) || (!this.promo.amount && !!this.promo.discount))
    }
  }
};
</script>