<template>
  <div
    :class="{
      'border-card': rounded,
    }"
  >
    <v-card
      :color="color"
      :flat="flat"
      :max-height="maxHeight"
      :min-height="minHeight"
      :loading="loading"
      :disabled="loading"
      style="overflow-y: auto"
    >
      <v-card-text>
        <div class="stick-title" :style="'background-color: ' + this.color">
          <v-progress-linear
            v-if="loading"
            class="text-progress pa-0"
            height="15"
            indeterminate
            ref="loader"
          ></v-progress-linear>
          <div style="font-size: 15px; font-weight: bold">
            {{ localTitle }}       
            <slot v-if="!!showHeaderActions" name="header-actions"></slot>
            <v-tooltip allow-overflow top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  v-if="!!selectablePayingVersion"
                  @click="clickSelectablePayingVersionButton()"
                >
                  <v-icon>mdi-tag-multiple</v-icon>
                </v-btn>
              </template>
              <span>Paga Tutto</span>
            </v-tooltip>
            <v-tooltip allow-overflow top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-on="on"
                  v-bind="attrs"
                  v-if="!!selectableDiscountOnTotal"
                  :disabled="!localItems || localItems.length == 0"
                  @click="$emit('show-general-discount-form')"
                >
                  <v-icon>mdi-sale</v-icon>
                </v-btn>
              </template>
              <span>Assegna Sconto Generale</span>
            </v-tooltip>
          </div>
          <v-divider class="mt-2"></v-divider>
        </div>
        <v-list class="mt-1 transparent">
          <slot name="list-item">
              <v-list-item-subtitle 
                v-for="(item, y) in localItems" :key="y"
                @dblclick="handleItemDoubleClick(item)"
              >
                <v-list-item-subtitle style="overflow-x: auto">
                  <div
                    style="font-size: 17px; font-weight: bold; width: 92%"
                    class="d-flex flex-row"
                  >
                    <v-row>
                      <v-col>
                        {{ getItemDescription(item) }} {{ item.tax == 10 ? '' : item.tax + '%'}}
                        <v-btn
                          icon
                          v-if="!!selectablePayingVersion"
                          @click="$emit('add-item-toPay', item)"
                          :disabled="(Number(item.quantity) == Number(item.quantityBeingPaid) + Number(item.paidQuantity))"
                        >
                          <v-icon>mdi-tag-arrow-down</v-icon>
                        </v-btn>
                      </v-col>
                      <v-col>
                        <v-badge
                          v-if="item.ghost"
                          dot 
                          color="error"
                        ></v-badge>
                      </v-col>
                      <v-col class="mr-3 d-flex justify-end">
                        € {{ Number(Number(getItemPrice(item) * getItemQuantity(item)).toFixed(2)) }}
                      </v-col>
                    </v-row>
                  </div>
                  <v-row class="pa-0 ma-0" style="width: 92%">
                    <v-col class="pa-0 ma-0" v-if="!selectablePayingVersion">
                      <div class="mt-3 d-flex justify-start">
                        Quantità: {{ getItemQuantity(item) }}
                      </div>
                    </v-col>
                    <v-col class="pa-0 ma-0" v-else-if="!!selectablePayingVersion">
                      <div class="mt-3 d-flex justify-start">
                        Ordinata: {{ getItemQuantity(item) }} Pagata:
                        {{ getPaidQuantity(item) }}
                      </div>
                    </v-col>
                    <v-col class="pa-0 ma-0">
                      <div class="d-flex justify-end" v-if="!!showActions">
                        <v-btn small @click="($emit('add-item', item))" icon fab
                          ><v-icon>mdi-plus</v-icon>
                        </v-btn>
                        <v-btn small @click="$emit('remove-item', item)" :disabled="Number(item.quantity) == item.paidQuantity" icon fab
                          ><v-icon>mdi-minus</v-icon>
                        </v-btn>
                        <!--<v-btn small @click="$emit('delete-item', item)" icon fab
                          ><v-icon>mdi-trash-can</v-icon>
                        </v-btn>-->
                        <v-btn small :outlined="item.tax == 10" :color="item.tax == 10 ? 'primary' : undefined" @click="item.tax != 10 ? $emit('select-not-tax', item) : ()=>{return}" icon fab v-if="!!item.itemId"
                          >10%
                        </v-btn>
                        <v-btn small :outlined="item.tax != 10" :color="item.tax != 10 ? 'primary' : undefined" @click="item.tax == 10 ? $emit('select-tax', item) : ()=>{return}" icon fab v-if="!!item.itemId"
                          >22%
                        </v-btn>
                      </div>
                      <div
                        class="d-flex justify-end"
                        v-else-if="!showActions && !selectablePayingVersion"
                      >
                        <v-btn small @click="$emit('remove-item-toPay', item)" icon fab :disabled="allPay"
                          ><v-icon>mdi-tag-arrow-up</v-icon>
                        </v-btn>
                        <v-btn small @click="$emit('add-discount', item)" icon fab
                          ><v-icon>mdi-sale</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
                <v-divider></v-divider>
              </v-list-item-subtitle>
          </slot>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "ListItemComponent",
  props: {
    itemDescription: {
      default: "description",
    },
    itemQuantity: {
      default: "quantity",
    },
    itemPrice: {
      default: "price",
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    maxWidht: {
      type: String,
      default: "100vw",
    },
    maxHeight: {
      type: String,
      default: "30vh",
    },
    minWidht: {
      type: String,
      default: "100vw",
    },
    minHeight: {
      type: String,
      default: "30vh",
    },
    color: {
      type: String,
      default: "white",
    },
    showHeaderActions: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Lista",
    },
    items: {
      type: Array,
      default: () => [],
    },
    bus: {
      type: Object,
      default: function () {
        return new Vue();
      },
    },
    selectablePayingVersion: {
      type: Boolean,
      default: false,
    },
    selectableDiscountOnTotal: {
      type: Boolean,
      default: false,
    },
    allPay:{
      type: Boolean,
      default: false
    },
    loading:{
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.bus.$on("");
    this.resetItemToPay()
  },
  data() {
    return {
      localTitle: this.title,
      localItems: this.items,
    };
  },
  methods: {
    getItemDescription(item) {
      if (typeof this.itemDescription == "function") {
        return this.itemDescription(item);
      } else return item[this.itemDescription];
    },
    getItemPrice(item) {
      if (typeof this.itemPrice == "function") {
        return this.itemPrice(item) ? this.itemPrice(item) : 0;
      } else return item[this.itemPrice] ? item[this.itemPrice] : 0;
    },
    getItemQuantity(item) {
      if (typeof this.itemQuantity == "function") {
        return this.itemQuantity(item) ? this.itemQuantity(item) : 0;
      } else return item[this.itemQuantity] ? item[this.itemQuantity] : 0;
    },
    getPaidQuantity(item) {
      return !!item.paidQuantity ? item.paidQuantity : 0;
    },
    clickSelectablePayingVersionButton() {
      for(const elem of this.localItems){
        const quantityToPay = Number(elem.quantity) - elem.quantityBeingPaid - elem.paidQuantity
        for(let i = 0; i < quantityToPay;i++)
          this.$emit('add-item-toPay', elem)
      }
    },
    resetItemToPay() {
      //non usata perchè non reisco a farla partire alla chiusura del MenuDialog
      for(const elem of this.localItems){
        for(let i = 0; i < elem.quantityBeingPaid;i++)
          this.$emit('remove-item-toPay', elem)
      }
    },
    handleItemDoubleClick(item) {
      this.$emit('item-ghost', item)
    }
  },
  watch: {
    items(newVal) {
      this.localItems = newVal;
    },
  },
};
</script>

<style scoped>
.border-card {
  padding: 5px;
  border-radius: 25px;
  border: 1px solid #dcd8d8;
}
.stick-title {
  position: sticky;
  top: 0;
  z-index: 1;
}
</style>